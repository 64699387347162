import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import React, { Fragment } from "react";
import {
  useNavigate,
  useParams,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import { useEditingContext } from "../contexts/EditingContext";
import { getDashboardRoute } from "../routes";
import { useGranularityFromParams } from "../hooks/useGranularityFromParams/useGranularityFromParams";
import { useDateFromParams } from "../hooks/useDateFromParams/useDateFromParams";
import { format } from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import { RestaurantOption } from "../types";
import { useRestaurantData } from "../hooks/useRestaurantData/useRestaurantData";

type TParams = { restaurantId: string };

const RestaurantSelector = () => {
  const [searchParams] = useSearchParams();
  const { restaurantId } = useParams<TParams>();
  const { isEditingHourly } = useEditingContext();
  const { granularity } = useGranularityFromParams({ searchParams });
  const { userChosenDate } = useDateFromParams({ searchParams });
  const navigate = useNavigate();

  const { data: restaurants, isLoading } = useRestaurantData();

  const handleRestaurantSelect = (
    _event: React.ChangeEvent<object>,
    value: RestaurantOption | null,
  ) => {
    if (value) {
      navigate({
        pathname: getDashboardRoute(value.restaurantId),
        search: createSearchParams({
          date: format(userChosenDate, "yyyy-MM-dd"),
          view: granularity,
        }).toString(),
      });
    }
  };

  const selectedRestaurant = restaurants.find(
    (restaurant) => restaurant.restaurantId === restaurantId,
  );

  return (
    <Autocomplete
      loading={isLoading}
      options={restaurants}
      getOptionLabel={(option) =>
        `[${option.restaurantId}] ${option.restaurantName} `
      }
      onChange={handleRestaurantSelect}
      disabled={isEditingHourly}
      defaultValue={selectedRestaurant}
      disableClearable
      sx={{ width: 300 }}
      noOptionsText={"No restaurants found"}
      filterOptions={(options, params) => {
        return options.filter(
          (option) =>
            option.restaurantName
              .toLowerCase()
              .includes(params.inputValue.toLowerCase()) ||
            option.restaurantId
              .toLowerCase()
              .includes(params.inputValue.toLowerCase()),
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          defaultValue={restaurantId}
          label={"Restaurant"}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default RestaurantSelector;
