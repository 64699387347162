import React, { useState } from "react";
import CustomizedDialog from ".";
import {
  CREATE_ADJUSTMENT,
  GET_ADJUSTMENT_REASONS2,
  GET_COMBINED_DATA,
} from "../../gql";
import ReasonHandler from "./ReasonHandler";
import AdjustmentTable from "./AdjustmentTable";
import ControlField from "./ControlField";
import { ICombinedRows, ReasonResponse } from "../../types";
import { useMutation } from "@apollo/client";
import getCurrency from "../../lib/getCurrency";
import getSymbolFromCurrency from "currency-symbol-map";
import { toast, ToastOptions } from "react-toastify";
import { AdjustmentDialogProps } from "../../types/dialogs";

const options: ToastOptions = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const DEFAULT_REASON = "Generated forecast accepted";
const MIN_CHARACTERS = 5;

const AdjustmentDialog = (props: AdjustmentDialogProps) => {
  const dailyRow = props.selectedRow as ICombinedRows;

  const {
    formattedBusinessDate,
    total,
    forecastId,
    businessDate,
    multiplier,
    restaurantId,
    totalOffset,
  } = dailyRow;

  const [currentSelection, setCurrentSelection] = useState<ReasonResponse>({
    id: "",
    description: "",
    otherValue: "",
  });

  const [newMultiplier, setNewMultiplier] = useState(dailyRow.multiplier || 1);
  const adjustedTotal = total + totalOffset;

  const [createAdjustment] = useMutation(CREATE_ADJUSTMENT, {
    refetchQueries: [GET_COMBINED_DATA, "combined_data"],
  });

  const handleSelectionChange = (x: ReasonResponse) => {
    setCurrentSelection(x);
  };

  const handleControlFieldChange = (x: number) => {
    setNewMultiplier(Math.round(x - totalOffset) / Math.round(total));
  };

  const handleSubmit = () => {
    if (
      (currentSelection.description === DEFAULT_REASON && newMultiplier != 1) ||
      (currentSelection.description != DEFAULT_REASON && newMultiplier === 1)
    ) {
      toast.warning(`Please review Adjusted Total or Reason`, {
        ...options,
        autoClose: 5000,
      });
      return;
    }
    if (
      currentSelection.description === "Other" &&
      currentSelection.otherValue.length < MIN_CHARACTERS
    ) {
      toast.warning(
        `Please be more descriptive, min ${MIN_CHARACTERS} characters`,
        {
          ...options,
          autoClose: 5000,
        },
      );
      return;
    }
    createAdjustment({
      variables: {
        forecastId: forecastId,
        businessDate,
        restaurantId: restaurantId,
        multiplier: newMultiplier,
        adjustment_reasonId: parseInt(currentSelection.id),
        adjustment_otherReason: currentSelection.otherValue,
      },
    })
      .then(() => {
        toast.success("Adjustment recorded 🚀", { ...options });
      })
      .catch((error) => {
        toast.error(`Adjustment not recorded 😢`, {
          ...options,
          autoClose: 5000,
        });
        throw error;
      });
    props.handleClose();
  };

  const currencySymbol = getSymbolFromCurrency(getCurrency(restaurantId));

  return (
    <>
      <CustomizedDialog
        title={`FORECAST VALIDATION - ${formattedBusinessDate}`}
        actionTitle="ADJUST THIS FORECAST"
        {...props}
        content={
          <AdjustmentTable
            selectedRow={dailyRow}
            currencySymbol={currencySymbol!}
            newMultiplier={newMultiplier}
            adjustedTotal={adjustedTotal}
          />
        }
        controlField={
          <ControlField
            defaultValue={Math.round(total * multiplier + totalOffset)}
            currencySymbol={currencySymbol}
            handleChange={(x) => handleControlFieldChange(x)}
          />
        }
        selector={
          <ReasonHandler
            defaultReasonDescription={DEFAULT_REASON}
            recordedReason={props.selectedRow.adjustment_reasonId as string}
            recordedOtherValue={props.selectedRow.adjustment_otherReason}
            gqlReasonQuery={GET_ADJUSTMENT_REASONS2}
            handleChange={(x) => handleSelectionChange(x)}
          />
        }
        enableMultiSubmit={false}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default AdjustmentDialog;
