import React from "react";
import { useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Skeleton from "@mui/material/Skeleton";
import { useRestaurantData } from "../../hooks/useRestaurantData/useRestaurantData";

const useStyles = makeStyles({
  subtitle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontWeight: "normal",
    marginLeft: "5px",
  },
});

export const RestaurantTitle = () => {
  const classes = useStyles();

  const { data: restaurants, isLoading } = useRestaurantData();

  if (isLoading) {
    return <Skeleton variant="text" width={100} />;
  }

  const { restaurantId } = useParams<{ restaurantId: string }>();
  const restaurant = restaurants.find((r) => r.restaurantId === restaurantId);

  if (!restaurant) {
    return (
      <Typography variant="h6" color="inherit" className={classes.subtitle}>
        Forecasting
      </Typography>
    );
  }

  return (
    <Typography variant="h6" color="inherit" className={classes.subtitle}>
      {restaurant.restaurantName}
    </Typography>
  );
};
