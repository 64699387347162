const environmentRegex = /(\w*\.)?forecast\.nandoca\.family/;
const getEnvironment = (hostname: string): string => {
  const found = hostname.match(environmentRegex);
  let environment = "local";
  if (found != null) {
    environment =
      found[1] !== undefined ? found[1].replace(".", "") : "production";
  }
  return environment;
};

export default getEnvironment;
