import React, { useState } from "react";
import { Event } from "../../../types";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import { WoosmapMap } from "../../map/Map";
import Box from "@mui/material/Box";
import { format } from "date-fns";
import Typography from "@mui/material/Typography";
import { startCase } from "lodash";
import { ExpandMore } from "@mui/icons-material";
interface props {
  event: Event;
}

export const RestaurantEventsListItem = ({ event }: props) => {
  const [expanded, setExpanded] = useState(false);
  const handleAccordionChange = (
    event: React.SyntheticEvent,
    isExpanded: boolean,
  ) => {
    setExpanded(isExpanded);
  };

  const {
    title,
    description,
    time,
    location,
    phq_attendance,
    category,
    business_date,
    state,
  } = event;

  const { start, end } = time;

  const formattedStart = format(new Date(start), "h:mm a");
  const formattedEnd = format(new Date(end), "h:mm a");

  const formattedDate = format(new Date(business_date), "EEE dd/MM/yyyy");

  const times =
    formattedStart === formattedEnd
      ? `${formattedStart} onwards`
      : `${formattedStart} - ${formattedEnd}`;

  const formattedDateAndTimes = `${formattedDate}, ${times}`;

  return (
    <Accordion onChange={handleAccordionChange} disabled={state === "deleted"}>
      <AccordionSummary expandIcon={<ExpandMore sx={{ color: "black" }} />}>
        <Box
          width={"100%"}
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          sx={{ textDecoration: state === "deleted" ? "line-through" : "none" }}
        >
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography variant={"h6"}>{title}</Typography>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            marginRight={2}
          >
            <Typography>{formattedDateAndTimes}</Typography>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box display={"flex"} gap={2} flexDirection={"column"}>
          <Box>
            <Typography variant={"h6"}>Description</Typography>
            <Typography variant={"body1"}>
              {description ? description : "No description available"}
            </Typography>
          </Box>
          <Box display={"flex"} flexDirection={"row"}>
            <Box flex={1}>
              <Typography variant={"h6"}>Estimated Attendance</Typography>
              <Typography variant={"body1"}>
                {phq_attendance
                  ? phq_attendance
                  : "No estimated attendance available"}
              </Typography>
            </Box>
            <Box flex={1}>
              <Typography variant={"h6"}>Category</Typography>
              <Typography variant={"body1"}>
                {category ? startCase(category) : "No category available"}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography variant={"h6"}>Location</Typography>
          {expanded && (
            <Box
              sx={{
                height: "200px",
                width: { s: "200px", md: "600px" },
                marginY: "12px",
              }}
            >
              <WoosmapMap
                woosmapConfig={{
                  center: {
                    lat: location.lng,
                    lng: location.lat,
                  },
                  zoom: 12,
                }}
              />
            </Box>
          )}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
