import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router-dom";
import BaseErrorPage from "../components/BaseErrorPage";
import Layout from "../components/Layout";
import ServiceDeskEmailLink from "../components/ServiceDeskEmailLink";

const LoggedOut: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();

  if (authState?.isAuthenticated) {
    return <Navigate to="/restaurant" />;
  }

  const login = async () => {
    await oktaAuth.signInWithRedirect();
  };

  return (
    <Layout>
      <BaseErrorPage
        heading="You are logged out"
        bodyPrimary="Please log in using your Okta account"
        bodySecondary={
          <>
            If you have any issues logging in to Sales Forecasting, please
            contact Service desk on <ServiceDeskEmailLink /> or call them on
            0203 040 5600.
          </>
        }
        button={{ label: "Log in", onClick: login }}
      />
    </Layout>
  );
};
export default LoggedOut;
