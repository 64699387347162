export const granularityObject = {
  Hourly: "Hourly",
  Daily: "Daily",
  Weekly: "Weekly",
} as const;

export type Granularity =
  (typeof granularityObject)[keyof typeof granularityObject];

export interface AddAdjustment
  extends Omit<
    IAdjustment,
    | "adjustmentId"
    | "fk_forecastId"
    | "adjustment_createdBy"
    | "adjustment_createdAt"
  > {
  forecastId: number;
}

interface IAdjustment {
  adjustmentId: number;
  fk_forecastId: number;
  businessDate: Date;
  restaurantId: string;
  multiplier: number;
  adjustment_reasonId: number | string;
  adjustment_otherReason: string;
  adjustment_createdBy: string;
  adjustment_createdAt: Date | null;
  rebaseline: boolean;
  previousMultiplier: number;
  latestUserAdjustmentId: number;
  latestAdjustedForecastId?: number;
  percentageChange: number;
  showAlert: boolean;
}

class Adjustment implements IAdjustment {
  constructor(date: Date, restaurantId: string) {
    this.adjustmentId = 0;
    this.fk_forecastId = 0;
    this.businessDate = date;
    this.restaurantId = restaurantId;
    this.multiplier = 1;
    this.adjustment_createdBy = "system";
    this.adjustment_createdAt = null;
    this.adjustment_reasonId = "";
    this.adjustment_otherReason = "";
    this.rebaseline = false;
    this.previousMultiplier = 1;
    this.latestUserAdjustmentId = 0;
    this.percentageChange = 0;
    this.showAlert = false;
  }
  adjustmentId: number;
  fk_forecastId: number;
  businessDate: Date;
  restaurantId: string;
  multiplier: number;
  adjustment_reasonId: string | number;
  adjustment_otherReason: string;
  adjustment_createdBy: string;
  adjustment_createdAt: Date | null;
  rebaseline: boolean;
  previousMultiplier: number;
  latestUserAdjustmentId: number;
  latestAdjustedForecastId?: number;
  percentageChange: number;
  showAlert: boolean;
}
export interface DateRange {
  start: Date;
  end: Date;
}

interface IForecast {
  forecastId: number;
  businessDate: Date;
  eatIn: number;
  delivery: number;
  collect: number;
  total: number;
  weekId: number;
}

export interface PreviousForecast {
  previous_forecastId: number;
  previous_businessDate: Date;
  previous_eatIn: number;
  previous_delivery: number;
  previous_collect: number;
  previous_total: number;
}

interface LatestForecast {
  latest_forecastId: number;
  latest_businessDate: Date;
  latest_eatIn: number;
  latest_delivery: number;
  latest_collect: number;
  latest_total: number;
}

class Forecast implements IForecast {
  constructor(date: Date) {
    this.forecastId = 0;
    this.eatIn = 0;
    this.delivery = 0;
    this.collect = 0;
    this.total = 0;
    this.weekId = 0;
    this.businessDate = date;
  }
  forecastId: number;
  businessDate: Date;
  eatIn: number;
  delivery: number;
  collect: number;
  total: number;
  weekId: number;
}

interface ISale {
  salesId: number;
  restaurantId: string;
  businessDate: Date;
  actual_eatIn: number;
  actual_delivery: number;
  actual_collect: number;
  actual_total: number;
}

class Sale implements ISale {
  constructor(date: Date, restaurantId: string) {
    this.salesId = 0;
    this.restaurantId = restaurantId;
    this.actual_eatIn = 0;
    this.actual_delivery = 0;
    this.actual_collect = 0;
    this.actual_total = 0;
    this.businessDate = date;
  }
  salesId: number;
  restaurantId: string;
  businessDate: Date;
  actual_eatIn: number;
  actual_delivery: number;
  actual_collect: number;
  actual_total: number;
}

interface IVariance {
  varianceId: number;
  businessDate: Date;
  restaurantId: string;
  totalForecast: number;
  totalSales: number;
  variance_reasonId: number | string;
  variance_otherReason: string;
  variance_createdBy: string;
  variance_createdAt: Date | null;
}

class Variance implements IVariance {
  constructor(date: Date, restaurantId: string) {
    this.varianceId = 0;
    this.businessDate = date;
    this.restaurantId = restaurantId;
    this.totalForecast = 0;
    this.totalSales = 0;
    this.variance_reasonId = "";
    this.variance_otherReason = "";
    this.variance_createdBy = "system";
    this.variance_createdAt = null;
  }
  varianceId: number;
  businessDate: Date;
  restaurantId: string;
  totalForecast: number;
  totalSales: number;
  variance_reasonId: string | number;
  variance_otherReason: string;
  variance_createdBy: string;
  variance_createdAt: Date | null;
}

interface IUIFlags {
  isTotalRow?: boolean;
  showAlert: boolean;
}

export interface ICombinedRecord
  extends IAdjustment,
    LatestForecast,
    PreviousForecast,
    IForecast,
    ISale,
    IVariance,
    IUIFlags {
  collectOffset: number;
  deliveryOffset: number;
  eatInOffset: number;
  totalOffset: number;
}

export interface ICombinedRows extends ICombinedRecord {
  id: number;
  formattedBusinessDate: string;
  businessDate: Date;
}

export type CombinedRecord = IAdjustment &
  LatestForecast &
  IForecast &
  ISale &
  IVariance &
  IUIFlags;

export interface IFineGrainValue {
  originalValue: number;
  isAdjusted: boolean;
  offsetValue: number;
}

export interface IHourlyEditDiff {
  id: number;
  originalEatIn: number;
  originalDelivery: number;
  originalCollect: number;
  businessDate: string;
  channelOffset: {
    eatIn: number | null;
    delivery: number | null;
    collect: number | null;
  };
  dataToPost: {
    forecastId: number;
    businessTime: Date;
    restaurantId: string;
  };
}

export interface IFineGrainedForecast {
  forecastId: number;
  businessDate: Date;
  eatIn: IFineGrainValue;
  delivery: IFineGrainValue;
  collect: IFineGrainValue;
  total: number;
  weekId: number;
}

export interface IUpdateFineGrainedForecast {
  forecastId: number;
  businessDate: Date;
  eatIn: number | IFineGrainValue;
  delivery: number | IFineGrainValue;
  collect: number | IFineGrainValue;
  total: number;
  weekId: number;
}

export interface IHourlyRecords extends IFineGrainedForecast, IAdjustment {
  actual_total?: number;
}

export interface IUpdateHourlyRecords
  extends IUpdateFineGrainedForecast,
    IAdjustment {}
export interface IHourlyForecastRows extends IHourlyRecords {
  id: number;
  formattedBusinessDate: string;
  businessDate: Date;
  isTotalRow?: boolean;
}
export interface IUpdateHourlyForecastRows extends IUpdateHourlyRecords {
  id: number;
  formattedBusinessDate: string;
  businessDate: Date;
  isTotalRow?: boolean;
}

export type IHourlyTotalRow = Partial<IHourlyForecastRows>;

export type IHourlyUpdate = Partial<IUpdateHourlyForecastRows>;

// TODO this object is no longer only ForecastsAndAdjustments
// come up with a better name?
export interface CombinedData {
  adjustments: IAdjustment[];
  forecasts: IForecast[];
  import_metadata: ImportMetadata;
  sales: ISale[];
  variances: IVariance[];
  hourlyAdjustments?: IHourlyAdjustment[];
}

export interface IHourlyAdjustment {
  business_time: Date;
  channel: "delivery" | "eatIn" | "collect";
  value: number;
  createdBy: string;
}

export interface MetaDataForecasts {
  forecastId: number;
  forecastedDate: Date;
  rowCount: number;
  createdAt: Date;
  fileName: string;
}
export interface MetaDataSales {
  salesId: number;
  salesDate: Date;
  rowCount: number;
  createdAt: Date;
  fileName: string;
}

export interface ImportMetadata {
  forecasts: MetaDataForecasts[];
  sales: MetaDataSales[];
}

export interface Reason {
  id: number;
  description: string;
  is_enabled: boolean;
}

export interface ReasonResponse {
  id: string;
  description: string;
  otherValue: string;
}
export interface AdjustmentReasons {
  adjustment_reasons: Reason[];
}

export interface VarianceReasons {
  variance_reasons: Reason[];
}

export interface Reasons {
  reasons: Reason[];
}

export interface RestaurantOption {
  restaurantId: string;
  restaurantName: string;
}

export interface Event {
  title: string;
  description: string;
  business_date: string;
  category: string;
  location: {
    lat: number;
    lng: number;
  };
  phq_attendance: number;
  phq_id: string;
  restaurant_no: string;
  time: {
    start: string;
    end: string;
  };
  state: "active" | "deleted" | "predicted";
}

export interface SelectedRow extends ICombinedRows {}

export type HourlyEditToggle = "total" | "channel";
export type TParams = {
  restaurantId: string;
};

export { Adjustment, Forecast, Sale, Variance };
