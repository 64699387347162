import { IHourlyForecastRows } from "../../../../types";

export const channelsFromRowUpdate = ({
  updatedRow,
  originalRow,
}: {
  updatedRow: IHourlyForecastRows;
  originalRow: IHourlyForecastRows;
}) => {
  const { eatIn, collect, delivery } = originalRow;
  const { total: newTotal } = updatedRow;

  if (newTotal < 0) return null;

  const originalTotal =
    eatIn.originalValue + collect.originalValue + delivery.originalValue;

  if (originalTotal === 0) {
    const equalSplit = Math.round(newTotal / 3);
    return {
      eatInOffset: equalSplit,
      collectOffset: equalSplit,
      deliveryOffset: equalSplit,
    };
  }

  const multiplier = newTotal / originalTotal;

  if (multiplier === 1) return null;

  return {
    eatInOffset: Math.round(
      eatIn.originalValue * multiplier - eatIn.originalValue,
    ),
    collectOffset: Math.round(
      collect.originalValue * multiplier - collect.originalValue,
    ),
    deliveryOffset: Math.round(
      delivery.originalValue * multiplier - delivery.originalValue,
    ),
  };
};
