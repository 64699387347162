import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { GridRowParams } from "@mui/x-data-grid";
import * as React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export interface ControlProps {
  open: boolean;
  handleOpen: (params: GridRowParams) => void;
  handleClose: () => void;
  handleSubmit: () => void;
  handleAccept?: () => void;
}

interface CustomizedDialogProps extends ControlProps {
  title: string;
  actionTitle: string;
  content?: React.ReactNode;
  controlField?: React.ReactNode;
  selector?: React.ReactNode;
  enableMultiSubmit?: boolean;
}

const CustomizedDialog = ({
  open,
  handleClose,
  handleSubmit,
  title,
  actionTitle,
  content,
  controlField,
  selector,
  enableMultiSubmit,
  handleAccept,
}: CustomizedDialogProps) => {
  return (
    <Box>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullWidth
        maxWidth="md"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>
          <Box
            component="span"
            sx={{
              p: 1,
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={12}>
                <Typography variant="h6">{actionTitle}</Typography>
              </Grid>
              <Grid item container xs={6} spacing={2}>
                <Grid
                  item
                  xs={12}
                  style={{ display: controlField ? "block" : "none" }}
                >
                  {controlField}
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{ display: selector ? "block" : "none" }}
                >
                  {selector}
                </Grid>
              </Grid>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-end"
                xs={6}
                spacing={2}
              >
                <Grid item>
                  <Button variant="outlined" onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>

                {enableMultiSubmit ? (
                  <>
                    <Grid item style={{ display: "block" }}>
                      <Button variant="outlined" onClick={handleSubmit}>
                        Keep Current
                      </Button>
                    </Grid>
                    <Grid item style={{ display: "block" }}>
                      <Button variant="contained" onClick={handleAccept}>
                        Accept New
                      </Button>
                    </Grid>
                  </>
                ) : (
                  <Grid item style={{ display: "block" }}>
                    <Button variant="contained" onClick={handleSubmit}>
                      Confirm
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Box>
        </DialogActions>
      </BootstrapDialog>
    </Box>
  );
};

export default CustomizedDialog;
