import { Box } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import { forecastMetadataType } from "../../types/schemas";
import { SummaryBox } from "./Layouts/SummaryBox";
import config from "../../lib/configApi";
import { DisclaimerCopy } from "./DisclaimerCopy";
import { LastUpdatedCopy } from "./LastUpdatedCopy";

interface props {
  forecasts: forecastMetadataType[] | undefined;
}
const DisclaimerBox = ({ forecasts }: props) => {
  const bucketObjUrl = `${config.bucket.bucket_object_url}/How_is_your_sales_forecast_generated.pdf`;

  return (
    <SummaryBox title={"Disclaimer"}>
      <Box display="flex" flexDirection={"column"}>
        <LastUpdatedCopy forecasts={forecasts} />
        <DisclaimerCopy />
        <Typography variant="body1" component="h2">
          Find out more about how the sales forecast is generated{" "}
          <a href={bucketObjUrl} target="_blank" rel="noreferrer">
            {" "}
            here
          </a>
        </Typography>
      </Box>
    </SummaryBox>
  );
};

export default DisclaimerBox;
