import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Granularity, granularityObject } from "../../types";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import React from "react";
import { useGranularityFromParams } from "../../hooks/useGranularityFromParams/useGranularityFromParams";
import { useSearchParams } from "react-router-dom";
import { useEditingContext } from "../../contexts/EditingContext";

export const GranularityDropdown = () => {
  const { isEditingHourly, setIsEditingHourly } = useEditingContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const { granularity } = useGranularityFromParams({ searchParams });
  const handleGranularityChange = (event: SelectChangeEvent) => {
    const range = event.target.value as Granularity;

    if (isEditingHourly && range !== "Hourly") {
      const discard = confirm(
        "You have unsaved changes. Are you sure you want to discard them?",
      );
      if (!discard) {
        return;
      } else {
        setIsEditingHourly(false);
      }
    }

    setSearchParams((prevParams) => {
      prevParams.set("view", range);
      return prevParams;
    });
  };
  return (
    <FormControl>
      <InputLabel id="demo-simple-select-label">Granularity</InputLabel>
      <Select
        sx={{ paddingX: 1 }}
        defaultValue={granularity}
        labelId="granularity-select-label"
        id="timerange-select"
        value={granularity}
        label="Restaurant"
        onChange={handleGranularityChange}
        disabled={isEditingHourly}
      >
        {Object.keys(granularityObject).map((range, index) => {
          return (
            <MenuItem key={index} value={range} sx={{ textAlign: "left" }}>
              {range}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
