import { useForm } from "react-hook-form";
import React, { Fragment, useState } from "react";
import { sendFeedbackToSlack } from "../../../lib/slackClient";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { logToSentry } from "../../../lib/LogToSentry";

export const FeedbackDialog = () => {
  type FeedbackFormInputs = {
    feedback: string;
  };

  const { register, handleSubmit, reset } = useForm<FeedbackFormInputs>();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);

  const onSubmit = async (data: FeedbackFormInputs) => {
    const { feedback } = data;
    try {
      await sendFeedbackToSlack(feedback);
      setOpen(false);
      toast.success("Feedback sent successfully!");
    } catch (error) {
      toast.warning("Failed to send feedback, please try again later.");
      logToSentry(error as Error);
    }
    reset();
  };

  return (
    <Fragment>
      <Button
        variant={"contained"}
        color={"secondary"}
        onClick={handleClickOpen}
      >
        Give Feedback
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Share Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please share any feedback you have about this feature. We will use
            this to improve hourly adjustments in the future.
          </DialogContentText>
        </DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogContent>
            <TextField
              sx={{ width: "100%" }}
              id="outlined-multiline-static"
              label="Feedback"
              multiline
              rows={4}
              placeholder="All feedback share is anonymous."
              {...register("feedback", { required: true })}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button color={"secondary"} variant={"contained"} type={"submit"}>
              Submit
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Fragment>
  );
};
