import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import "@nandosuk/peri-verse-react-components/style.css";
import "./index.css";
import "./fonts/NandosHand.ttf";
import App from "./App";
import getEnvironment from "./lib/getEnvironment";
import * as serviceWorker from "./serviceWorker";

// eslint-disable-next-line
ReactDOM.render(<App />, document.getElementById("root"));

Sentry.init({
  dsn: "https://22f757e9e5f44de797fa640d76da9c0e@o181698.ingest.sentry.io/6389994",
  environment: getEnvironment(window.location.hostname),
  integrations: [new Integrations.BrowserTracing()],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
