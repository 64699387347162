import React from "react";
import { Link } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    textDecoration: "underline",
  },
}));

const ServiceDeskEmailLink: React.FC = () => {
  const classes = useStyles();

  return (
    <Link className={classes.link} href="mailto:itservicedesk@nandos.co.uk">
      itservicedesk@nandos.co.uk
    </Link>
  );
};

export default ServiceDeskEmailLink;
