const customFetch =
  (getAccessToken: () => string | undefined) =>
  async (uri: string, options?: RequestInit): Promise<Response> => {
    const accessToken = getAccessToken();

    const fetchOptions = {
      ...options,
      headers: {
        ...options?.headers,
        authorization: accessToken ? `Bearer ${accessToken}` : "",
      },
    };

    const response = await fetch(uri, fetchOptions);
    return response;
  };

export default customFetch;
