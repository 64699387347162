import React, { FunctionComponent } from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useOktaAuth } from "@okta/okta-react";
import AppBar, { AppBarProps } from "./index";
import { RestaurantTitle } from "./RestaurantTitle";

const useStyles = makeStyles({
  title: {
    marginLeft: "24px",
  },
  subtitle: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    fontWeight: "normal",
    marginLeft: "5px",
  },
  branchCode: {
    marginLeft: "12px",
    color: "rgba(255, 255, 255, 0.64)",
    fontWeight: "normal",
  },
  warningIcon: {
    color: "#fbff00",
    fontSize: "30px",
  },
});

const RestaurantAppBar: FunctionComponent<AppBarProps> = ({ onMenuClick }) => {
  const { authState } = useOktaAuth();
  return (
    <AppBar onMenuClick={onMenuClick}>
      {authState?.isAuthenticated ? (
        <RestaurantTitle />
      ) : (
        <RestaurantsFallbackTitle />
      )}
    </AppBar>
  );
};

const RestaurantsFallbackTitle: FunctionComponent = () => {
  const classes = useStyles();
  return (
    <Typography variant="h6" color="inherit" className={classes.title}>
      Sales Forecasting
    </Typography>
  );
};

export default RestaurantAppBar;
