import React from "react";
import { ThemeProvider as MUIThemeProvider, Theme } from "@mui/material/styles";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security, LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./components/SecureRoute";
import * as Sentry from "@sentry/react";
import {
  Route,
  Routes,
  BrowserRouter as Router,
  useNavigate,
} from "react-router-dom";
import "./App.css";
import authService from "./lib/authService";
import { staticRoutes, patterns } from "./routes";
import Help from "./pages/Help";
import LoggedOut from "./pages/LoggedOut";
import PageNotFound from "./pages/PageNotFound";
import ServiceNotAvailable from "./pages/ServiceNotAvailable";
import Restaurant from "./pages/Restaurant";
import theme from "./theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Maintenance from "./pages/Maintenance";
import DashboardWithProviders from "./pages/DashboardWithProviders";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import LogRocket from "logrocket";
import config from "./lib/configApi";

switch (config.environment) {
  case "preview":
    LogRocket.init("3qixyg/forecast-preview");
    break;
  case "preprod":
    LogRocket.init("3qixyg/forcast-dev");
    break;
  default:
    LogRocket.init("3qixyg/forecast-prod");
}

declare module "@mui/styles/defaultTheme" {
  interface DefaultTheme extends Theme {}
}

const Inner: React.FC = () => {
  const navigate = useNavigate();

  const restoreOriginalUri = async (_: OktaAuth, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
  };

  const queryClient = new QueryClient();

  // TODO investigate warning Two custom restoreOriginalUri callbacks are detected
  return (
    <QueryClientProvider client={queryClient}>
      <Security oktaAuth={authService} restoreOriginalUri={restoreOriginalUri}>
        <Sentry.ErrorBoundary fallback={<ServiceNotAvailable />}>
          <Routes>
            <Route path={staticRoutes.login} element={<LoginCallback />} />
            <Route path={staticRoutes.help} element={<Help />} />
            <Route path={staticRoutes.index} element={<LoggedOut />} />
            <Route path="/" element={<RequiredAuth />}>
              <Route
                path={patterns.dashboard}
                element={<DashboardWithProviders />}
              />
              <Route path={staticRoutes.restaurant} element={<Restaurant />} />
              <Route path={"*"} element={<PageNotFound />} />
            </Route>
            <Route path={staticRoutes.maintenance} element={<Maintenance />} />
          </Routes>
        </Sentry.ErrorBoundary>
      </Security>
    </QueryClientProvider>
  );
};

const App: React.FC = () => (
  <MUIThemeProvider theme={theme}>
    <Router>
      <Inner />
      <ToastContainer />
    </Router>
  </MUIThemeProvider>
);

export default Sentry.withProfiler(App);
