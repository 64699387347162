import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { FeedbackDialog } from "../Alert/FeedbackDialog";
import Button from "@mui/material/Button";
import Joyride, {
  Step,
  STATUS,
  EVENTS,
  CallBackProps,
  ACTIONS,
} from "react-joyride";
import { useEditingContext } from "../../../contexts/EditingContext";

export const HourlyHelpBox = () => {
  const steps: Step[] = [
    {
      target: "#hourly-layout",
      disableBeacon: true,
      title: "Welcome to the new hourly forecast layout!",
      content:
        "We will guide you through the new features and how to use them.",
    },
    {
      target: "#edit-toolbar",
      disableBeacon: true,
      title: "Edit Toolbar",
      content: (
        <div>
          <p>This toolbar contains the buttons to make your adjustments.</p>
        </div>
      ),
    },
    {
      target: "#edit-switch",
      disableBeacon: true,
      title: "The Edit Switch",
      content: (
        <div>
          <p>Use this switch to toggle editing mode.</p>
        </div>
      ),
    },
    {
      target: "#data-grid",
      disableBeacon: true,
      title: "Editing Mode",
      content: (
        <div>
          <p>
            You can tell when editing mode is enabled when the cells are{" "}
            <span style={{ background: "rgba(57, 161, 125, 0.25)" }}>
              green
            </span>
            .
          </p>
        </div>
      ),
    },
    {
      target: "#data-grid",
      disableBeacon: true,
      title: "Editing Cells",
      content: (
        <div>
          <p>
            When editing mode is on, you can edit the forecast by clicking on
            the cells and typing in a new value.
          </p>
        </div>
      ),
    },
    {
      target: "#edit-type",
      disableBeacon: true,
      title: "Change how you Edit",
      content: (
        <div>
          <p>
            If you prefer to make an adjustment for all channels at once, you
            can select to edit the{" "}
            <span style={{ background: "rgba(57, 161, 125, 0.25)" }}>
              total
            </span>
            .
          </p>
          <p>
            If you prefer to make adjustments for each channel separately, you
            can select to edit by{" "}
            <span style={{ background: "rgba(164,68,85,0.25)" }}>channel</span>.
          </p>
        </div>
      ),
    },
    {
      target: "#edit-buttons",
      disableBeacon: true,
      title: "Edit Buttons",
      content: (
        <div>
          <p>
            Save your changes by clicking the save button or you can discard all
            of your changes by clicking the discard button.
          </p>
        </div>
      ),
    },
    {
      target: "#hourly-layout",
      disableBeacon: true,
      title: "That's it!",
      content: (
        <div>
          If you have any questions, please send us feedback using the
          &apos;Give Feedback&apos; button in the help box.
        </div>
      ),
    },
  ];
  const [run, setRun] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const { setIsEditingHourly } = useEditingContext();

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, action } = data;

    if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      const direction = action === ACTIONS.NEXT ? 1 : -1;
      setStepIndex(stepIndex + direction);
      if (stepIndex > 0) {
        setIsEditingHourly(true);
      }
    }

    if (
      status === STATUS.FINISHED ||
      action === ACTIONS.CLOSE ||
      action === ACTIONS.SKIP
    ) {
      setRun(false);
      setStepIndex(0);
      setIsEditingHourly(false);
    }
  };

  const handleStartTutorial = () => {
    setRun(true);
  };

  return (
    <Box
      sx={{
        border: "1px solid rgba(224, 224, 224, 1)",
        borderRadius: "5px",
        flexGrow: 1,
      }}
    >
      <Box>
        <Box
          sx={{
            height: "56px",
            borderBottom: "1px solid rgba(224, 224, 224, 1)",
            display: "flex",
            alignItems: "center",
            padding: "0 1rem 0 1rem",
          }}
        >
          <Typography sx={{ margin: 0 }} fontWeight={"bold"}>
            Help
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            padding: "1rem",
            gap: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
            }}
          >
            <Button variant={"outlined"} onClick={handleStartTutorial}>
              Tutorial
            </Button>
            <FeedbackDialog />
          </Box>
        </Box>
      </Box>
      <Joyride
        steps={steps}
        run={run}
        showProgress={true}
        hideBackButton={false}
        continuous={true}
        disableOverlayClose={true}
        disableCloseOnEsc={true}
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
      />
    </Box>
  );
};
