import React, { createContext, ReactNode, useContext, useState } from "react";
import { HourlyEditToggle } from "../types";

interface EditingContextType {
  isEditingHourly: boolean;
  setIsEditingHourly: (isEditingHourly: boolean) => void;
  editType: HourlyEditToggle;
  setEditType: (editType: HourlyEditToggle) => void;
}

const EditingContext = createContext<EditingContextType>({
  isEditingHourly: false,
  setIsEditingHourly: () => true,
  editType: "total",
  setEditType: () => "total",
});

const EditingProvider = ({ children }: { children: ReactNode }) => {
  const [isEditingHourly, setIsEditingHourly] = useState<boolean>(false);
  const [editType, setEditType] = useState<HourlyEditToggle>("total");
  return (
    <EditingContext.Provider
      value={{ isEditingHourly, setIsEditingHourly, editType, setEditType }}
    >
      {children}
    </EditingContext.Provider>
  );
};

const useEditingContext = () => useContext(EditingContext);

export { EditingProvider, useEditingContext };
