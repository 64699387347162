import authService from "../../lib/authService";
import config from "../../lib/configApi";
import { useQuery } from "@tanstack/react-query";
import { Event } from "../../types";

export const useEventsData = (
  date: string,
  restaurantId?: string,
  shouldDisplayEvents?: boolean,
) => {
  return useQuery({
    queryKey: ["events", date, restaurantId],
    enabled: shouldDisplayEvents,
    initialData: [],
    queryFn: async () => {
      const res = await fetch(
        `${config.api.url}/events?date=${date}&restaurantId=${restaurantId}`,
        {
          headers: {
            Authorization: `Bearer ${authService.getAccessToken()}`,
          },
        },
      );

      if (!res.ok) {
        throw new Error("Something went wrong fetching events");
      }

      const { events }: { events: Event[] } = await res.json();

      if (!events) {
        return [];
      }

      return events;
    },
  });
};
