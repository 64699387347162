import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IHourlyEditDiff } from "../../../types";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import formatDiffNumber from "../../../lib/formatDiffNumber";

const HourlyChangesList = ({
  editedRows,
}: {
  editedRows: IHourlyEditDiff[];
}) => {
  if (editedRows.length === 0)
    return (
      <Box>
        <Typography>No changes to display!🐔</Typography>
      </Box>
    );

  return <HourlyEditGrid rows={editedRows} />;
};

const HourlyEditGrid = ({ rows }: { rows: IHourlyEditDiff[] }) => {
  const columns: GridColDef[] = [
    { field: "businessDate", headerName: "Time" },
    {
      field: "originalEatIn",
      headerName: "Eat-In",
      valueGetter: (params) => {
        const { row }: { row: IHourlyEditDiff } = params;
        if (row.channelOffset.eatIn)
          return formatDiffNumber({ number: row.channelOffset.eatIn });
        else return "-";
      },
    },
    {
      field: "originalDelivery",
      headerName: "Delivery",
      valueGetter: (params) => {
        const { row }: { row: IHourlyEditDiff } = params;
        if (row.channelOffset.delivery)
          return formatDiffNumber({ number: row.channelOffset.delivery });
        else return "-";
      },
    },
    {
      field: "originalCollect",
      headerName: "Collect",
      valueGetter: (params) => {
        const { row }: { row: IHourlyEditDiff } = params;
        if (row.channelOffset.collect)
          return formatDiffNumber({ number: row.channelOffset.collect });
        else return "-";
      },
    },
  ];

  return (
    <DataGrid
      columns={columns}
      rows={rows}
      pageSizeOptions={[]}
      hideFooter={true}
    />
  );
};

export default HourlyChangesList;
