import * as React from "react";
import { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Reasons, ReasonResponse } from "../../types";
import Collapse from "@mui/material/Collapse";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { DocumentNode, useLazyQuery } from "@apollo/client";

interface ReasonHandlerProps {
  defaultReasonDescription?: string;
  recordedReason?: string;
  recordedOtherValue?: string;
  gqlReasonQuery: DocumentNode;
  handleChange: (reasonResponse: ReasonResponse) => void;
}

const getReasonId = (reasons: Reasons, description?: string) => {
  return reasons.reasons
    .find((x) => x.description == description)
    ?.id.toString();
};

const getReasonDescription = (reasons: Reasons, id: string) => {
  return reasons.reasons.find((x) => x.id == parseInt(id))?.description || "";
};

const checkReasonOther = (reasons: Reasons, id: string) => {
  return (
    reasons.reasons.find((x) => x.id == parseInt(id))?.description === "Other"
  );
};

const ReasonHandler: React.FC<ReasonHandlerProps> = ({
  defaultReasonDescription,
  recordedReason,
  recordedOtherValue,
  handleChange,
  gqlReasonQuery,
}) => {
  const [getReasons, { data, loading }] = useLazyQuery<Reasons>(
    gqlReasonQuery,
    // added no-cache policy as the UI sporadically returned the wrong reason options
    {
      fetchPolicy: "no-cache",
    },
  );

  const CHARACTER_LIMIT = 120;

  const [selectedReasonId, setSelectedReasonId] = useState("");
  const [selectedReasonDescription, setSelectedReasonDescription] =
    useState("");
  const [otherReasonValue, setOtherReasonValue] = useState("");
  const [showOtherReason, setShowOtherReason] = useState(
    recordedOtherValue ? true : false,
  );

  const handleReasonSelection = (e: SelectChangeEvent) => {
    const { value } = e.target;
    setSelectedReasonId(value);
    data && setSelectedReasonDescription(getReasonDescription(data, value));
    if (data && checkReasonOther(data, value)) {
      setShowOtherReason(true);
    } else {
      setOtherReasonValue("");
      setShowOtherReason(false);
    }
  };

  const handleOtherReasonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setOtherReasonValue(value);
  };

  const reasonResponse = {
    id: selectedReasonId,
    description: selectedReasonDescription,
    otherValue: otherReasonValue,
  };

  useEffect(() => {
    handleChange(reasonResponse);
  }, [selectedReasonId, selectedReasonDescription, otherReasonValue]);

  useEffect(() => {
    if (!loading && data) {
      setSelectedReasonId(
        recordedReason || getReasonId(data, defaultReasonDescription) || "",
      );
      setSelectedReasonDescription(
        getReasonDescription(data, recordedReason || "") ||
          defaultReasonDescription ||
          "",
      );
      setOtherReasonValue(recordedOtherValue || "");
      if (checkReasonOther(data, recordedReason || "")) {
        setShowOtherReason(true);
      }
    }
  }, [loading]);

  useEffect(() => {
    getReasons();
    return;
  }, []);

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="reason-select-label">Reason</InputLabel>
        <Select
          defaultValue={selectedReasonId}
          label="Reason"
          labelId="reason-select-label"
          id="reason-select"
          onChange={handleReasonSelection}
          value={selectedReasonId}
          data-testid="reason-select"
        >
          {data &&
            [...data.reasons]
              .filter((x) => x.id && x.is_enabled)
              .sort((a, b) => a.description.localeCompare(b.description))
              .map(({ id, description }, index) => {
                return (
                  <MenuItem key={index} value={id}>
                    {description}
                  </MenuItem>
                );
              })}
        </Select>
      </FormControl>
      <Collapse in={showOtherReason}>
        <FormControl fullWidth sx={{ pt: 2 }}>
          <TextField
            id="outlined-multiline-static"
            label="Other Reason"
            inputProps={{
              maxLength: CHARACTER_LIMIT,
            }}
            multiline
            helperText={`${otherReasonValue.length}/${CHARACTER_LIMIT}`}
            required={showOtherReason}
            value={otherReasonValue}
            onChange={handleOtherReasonChange}
            rows={3}
          />
        </FormControl>{" "}
      </Collapse>
    </Box>
  );
};

export default ReasonHandler;
