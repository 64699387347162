import { createTheme, Theme } from "@mui/material/styles";

type Shades = {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
};

export type CustomTheme = Theme & {
  layout: {
    containerPadding: number;
  };
  palette: {
    primary: Shades;
    secondary: Shades;
    secondaryVariant: Shades;
    warning: Shades;
    action: {
      activeText: string;
      focusBorder: string;
      highlight: string;
    };
  };
};

const theme = createTheme({
  typography: {
    fontFamily: [
      "Roboto",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
    h1: {
      fontWeight: 400,
      fontSize: 34,
    },
    h2: {
      fontWeight: 400,
      fontSize: 20,
      lineHeight: 1.2,
      letterSpacing: 0.15,
    },
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  palette: {
    mode: "light",
    primary: {
      light: "#1f1f1f",
      main: "#000",
      dark: "#000",
    },
    secondary: {
      light: "#14E1C1",
      main: "#00555A",
      dark: "#00555A",
    },
    warning: {
      light: "#FFCC80",
      main: "#EF6C00",
      dark: "#EF6C00",
    },
    action: {
      active: "rgba(89, 192, 186, 0.54)",
      //hover: "rgba(89, 192, 186, 0.04)",
      focus: "rgba(89, 192, 186, 0.12)",
    },
    error: {
      main: "#CE0A24",
    },
  },
});

const customTheme: CustomTheme = {
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      ...theme.palette.primary,
      /// Getting material UI to give us the shades from the designs
      /// is not fun so just stick them here
      50: "#F5F5F5",
      100: "#E9E9E9",
      200: "#D9D9D9",
      300: "#C4C4C4",
      400: "#A5A5A5",
      500: "#7B7B7B",
      600: "#555555",
      700: "#434343",
      800: "#262626",
      900: "#000000",
    },
    secondary: {
      ...theme.palette.secondary,
      /// Getting material UI to give us the shades from the designs
      /// is not fun so just stick them here
      50: "#DFF5F9",
      100: "#AFE7EF",
      200: "#7CD7E4",
      300: "#48C6D8",
      400: "#20BBD0",
      500: "#00B0C8",
      600: "#00A0B5",
      700: "#008C9C",
      800: "#007884",
      900: "#00555A",
    },
    secondaryVariant: {
      /// Getting material UI to give us the shades from the designs
      /// is not fun so just stick them here
      50: "#D5F8F2",
      100: "#94EDDB",
      200: "#14E1C1",
      300: "#00D1A9",
      400: "#00C296",
      500: "#00B384",
      600: "#00A576",
      700: "#009465",
      800: "#008356",
      900: "#006538",
    },
    warning: {
      ...theme.palette.warning,
      50: "#FFF3E0",
      100: "#FFE0B2",
      200: "#FFCC80",
      300: "#FFB74D",
      400: "#FFA726",
      500: "#FF9800",
      600: "#FB8C00",
      700: "#F57C00",
      800: "#EF6C00",
      900: "#E65100",
    },
    action: {
      ...theme.palette.action,
      activeText: "rgba(0, 85, 90, 0.87)",
      focusBorder: "#00555a",
      highlight: "#94eddb",
    },
  },
  layout: {
    containerPadding: parseInt(theme.spacing(3), 10),
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
        body1: {
          marginBottom: "12px",
          marginTop: "12px",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlinedSecondary: {
          "&, &.MuiChip-clickable:focus": {
            backgroundColor: theme.palette.action.active,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          color: "black",
        },
      },
    },
  },
};

export default customTheme;
