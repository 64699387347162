const formatDiffNumber = ({ number }: { number: number }) => {
  if (number > 0)
    return `+${number.toLocaleString(undefined, {
      maximumFractionDigits: 0,
    })}`;
  if (number < 0)
    return `-${number
      .toLocaleString(undefined, {
        maximumFractionDigits: 0,
      })
      .slice(1)}`;
  return 0;
};

export default formatDiffNumber;
