import { useQuery } from "@tanstack/react-query";
import config from "../../lib/configApi";
import authService from "../../lib/authService";
import { RestaurantOption } from "../../types";

export const useRestaurantData = () => {
  return useQuery({
    queryKey: ["restaurants"],
    initialData: [],
    queryFn: async () => {
      const res = await fetch(`${config.api.url}/restaurants`, {
        headers: {
          Authorization: `Bearer ${authService.getAccessToken()}`,
        },
      });

      if (!res.ok) {
        throw new Error("Something went wrong fetching restaurants");
      }

      const { restaurants } = await res.json();

      const sortedData: RestaurantOption[] = restaurants.sort(
        (a: { restaurantName: string }, b: { restaurantName: string }) =>
          a.restaurantName.localeCompare(b.restaurantName),
      );

      return sortedData;
    },
  });
};
