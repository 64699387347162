import React, { ReactNode } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

interface props {
  children?: ReactNode;
  title?: string | ReactNode;
}
export const SummaryBox = ({ children, title }: props) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          border: "1px solid rgba(224, 224, 224, 1)",
          borderRadius: "5px",
          flexGrow: 1,
        }}
      >
        <Box>
          <Box
            sx={{
              height: "56px",
              borderBottom: "1px solid rgba(224, 224, 224, 1)",
              display: "flex",
              alignItems: "center",
              padding: "0 1rem 0 1rem",
            }}
          >
            <Typography
              sx={{ margin: 0, wordBreak: "break-all" }}
              fontWeight={"bold"}
            >
              {title && title}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "1rem",
              gap: "1rem",
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
