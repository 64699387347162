import { GranularitySchema } from "../../types/schemas";
import { Granularity } from "../../types";
interface props {
  searchParams: URLSearchParams;
}

export const useGranularityFromParams = ({
  searchParams,
}: props): { granularity: Granularity; weeksSelected: 1 | 6 } => {
  const granularity = searchParams.get("view");

  try {
    const granularityValue = GranularitySchema.nullable()
      .transform((granularity) => {
        if (!granularity) return "Daily";
        return granularity;
      })
      .pipe(GranularitySchema)
      .parse(granularity);

    const weeksSelected = granularityValue === "Weekly" ? 6 : 1;

    return { granularity: granularityValue, weeksSelected };
  } catch (error) {
    return { granularity: "Daily", weeksSelected: 1 };
  }
};
