import { useQuery } from "@apollo/client";
import Skeleton from "@mui/material/Skeleton";
import React from "react";
import { GET_COMBINED_DATA } from "../../gql";
import { logToSentry } from "../../lib/LogToSentry";
import { dateAsReverseUTC, getRows } from "../../lib/getForecastDataRecords";
import ServiceNotAvailable from "../../pages/ServiceNotAvailable";
import { combinedDataAPIResponse } from "../../types/schemas";
import WeeklyDataGrid from "./DataGrid/WeeklyDataGrid";
import DisclaimerBox from "./DisclaimerBox";
import DailyLayout from "./Layouts/DailyLayout";
import HourlyLayout from "./Layouts/HourlyLayout";
import { useGranularityFromParams } from "../../hooks/useGranularityFromParams/useGranularityFromParams";
import { useSearchParams } from "react-router-dom";
import { useDateFromParams } from "../../hooks/useDateFromParams/useDateFromParams";
import startOfWeek from "date-fns/startOfWeek";
import endOfDay from "date-fns/endOfDay";
import enGb from "date-fns/locale/en-GB";
import addsWeeks from "date-fns/addWeeks";
import endOfWeek from "date-fns/endOfWeek";
import { ICombinedRows, IHourlyForecastRows } from "../../types";

interface props {
  restaurantId: string;
}

const DataGrid = ({ restaurantId }: props) => {
  const [searchParams] = useSearchParams();

  const { granularity, weeksSelected } = useGranularityFromParams({
    searchParams,
  });
  const { userChosenDate } = useDateFromParams({ searchParams });

  const dateRange =
    granularity === "Hourly"
      ? {
          start: userChosenDate,
          end: endOfDay(userChosenDate),
        }
      : {
          start: startOfWeek(userChosenDate, { locale: enGb }),
          end: addsWeeks(
            endOfWeek(userChosenDate, { locale: enGb }),
            weeksSelected - 1,
          ),
        };

  const variables = {
    restaurantId,
    dateStart: dateAsReverseUTC(dateRange.start),
    dateEnd: dateAsReverseUTC(dateRange.end),
    granularity: granularity === "Hourly" ? "hour" : "day",
  };

  // We use no-cache because we want to be sure of the exact data we are getting.
  const { loading, error, data } = useQuery(GET_COMBINED_DATA, {
    variables: variables,
    fetchPolicy: "no-cache",
  });

  if (loading) return <Skeleton variant="rectangular" height={500} />;
  if (error) return <ServiceNotAvailable />;

  const parsedDataResult = combinedDataAPIResponse.safeParse(data);

  if (!parsedDataResult.success) {
    logToSentry(parsedDataResult.error);
    return <ServiceNotAvailable />;
  }

  const parsedData = parsedDataResult.data;
  const rows = getRows(
    restaurantId,
    { start: dateRange.start, end: dateRange.end },
    parsedData,
    granularity,
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflow: "auto",
        gap: "20px",
      }}
    >
      {rows.length > 0 && granularity === "Hourly" && (
        <HourlyLayout rows={rows as IHourlyForecastRows[]} />
      )}
      {rows.length > 0 && granularity === "Daily" && (
        <DailyLayout rows={rows as ICombinedRows[]} />
      )}
      {granularity === "Weekly" && <WeeklyDataGrid rows={rows} />}

      <DisclaimerBox forecasts={parsedData.import_metadata.forecasts} />
    </div>
  );
};

export default DataGrid;
