import React from "react";
import { Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Layout from "../components/Layout";

const useStyles = makeStyles({
  root: {
    padding: "24px",
  },
  text: {
    marginBottom: "12px",
  },
});

interface LinkProps {
  href: string;
  text: string;
}

const InlineLink: React.FC<LinkProps> = ({ text, href }) => {
  return (
    <Link href={href} target="_blank" rel="noopener noreferrer">
      {text}
    </Link>
  );
};

const Help: React.FC = () => {
  const classes = useStyles();
  return (
    <Layout>
      <div className={classes.root}>
        <Typography variant="h1">Help</Typography>
        <Typography variant="body1" className={classes.text}>
          For more information on using the Sales Forecasting tool you can visit
          the{" "}
          <InlineLink
            text="User Guide"
            href="https://nandoscentralsupport.zendesk.com/hc/en-gb/articles/9926585484817-Sales-Forecasting-App-User-Guide"
          />{" "}
          or the{" "}
          <InlineLink
            text="FAQ"
            href="https://nandoscentralsupport.zendesk.com/hc/en-gb/articles/9925388412561-Sales-Forecasting-Frequent-Asked-Questions-FAQ-"
          />{" "}
          page.
          <br />
          If you need further support or are having any issues, you can raise a
          ticket with the Technology Service Desk team via their{" "}
          <InlineLink
            text="self-service portal"
            href="https://support.nandos.co.uk"
          />
          .
          <br />
        </Typography>
      </div>
    </Layout>
  );
};

export default Help;
