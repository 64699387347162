import React, { useState } from "react";
import Box from "@mui/material/Box";
import { FormControlLabel, Stack, Switch, Tooltip } from "@mui/material";
import Button from "@mui/material/Button";
import HourlyDataGrid from "../DataGrid/hourly/HourlyDataGrid";
import { useEditingContext } from "../../../contexts/EditingContext";
import { Delete, Save } from "@mui/icons-material";
import HourlyEditDialog from "../Alert/HourlyEditDialog";
import { IHourlyEditDiff, IHourlyForecastRows } from "../../../types";
import { HourlySidebar } from "./HourlySidebar";
import { useParams, useSearchParams } from "react-router-dom";
import { useDateFromParams } from "../../../hooks/useDateFromParams/useDateFromParams";
import { HourlyEditToggleGroup } from "../HourlyEditToggleGroup";
import HourlyDataGridTotal from "../DataGrid/hourly/HourlyDataGridTotal";
import { useRestaurantTrial } from "../../../hooks/useRestaurantTrial/useRestaurantTrial";
import { RestaurantEventsList } from "./RestaurantEventsList";

interface hourlyLayoutTypes {
  rows: IHourlyForecastRows[];
}
type TParams = { restaurantId: string };

const HourlyLayout = ({ rows: inputRows }: hourlyLayoutTypes) => {
  const { restaurantId } = useParams<TParams>();

  const [rows, setRows] = useState<IHourlyForecastRows[]>(inputRows);
  const [editedRows, setEditedRows] = useState<IHourlyEditDiff[]>([]);
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const { data: isHourlyEnabled } = useRestaurantTrial(
    restaurantId,
    "hourly_forecast_adjustments",
  );

  const { isEditingHourly, setIsEditingHourly, editType } = useEditingContext();
  const { userChosenDate } = useDateFromParams({ searchParams });

  const isHourlyEditDisabled = userChosenDate < new Date();

  const totalForEachRow = (row: IHourlyForecastRows) => {
    const eatInValue = row.eatIn.isAdjusted
      ? row.eatIn.offsetValue + row.eatIn.originalValue
      : row.eatIn.originalValue;
    const collectValue = row.collect.isAdjusted
      ? row.collect.offsetValue + row.collect.originalValue
      : row.collect.originalValue;
    const deliveryValue = row.delivery.isAdjusted
      ? row.delivery.offsetValue + row.delivery.originalValue
      : row.delivery.originalValue;

    return eatInValue + collectValue + deliveryValue;
  };

  const currentForecast = inputRows.reduce(
    (acc, row) => acc + totalForEachRow(row),
    0,
  );

  const onSaveButtonClicked = () => setOpen(true);

  const onConfirmSave = () => {
    setOpen(false);
    setEditedRows([]);
    setIsEditingHourly(false);
  };

  const onConfirmDiscard = () => {
    setOpen(false);
    setRows(inputRows);
    setEditedRows([]);
    setIsEditingHourly(false);
    window.location.reload();
  };

  const handleSwitchChange = () => {
    if (!isEditingHourly) {
      setIsEditingHourly(true);
      return;
    }

    if (editedRows.length === 0) {
      setIsEditingHourly(false);
      return;
    }

    const answer = confirm(
      "You have unsaved changes. Are you sure you want to discard all changes?",
    );
    if (answer) {
      onConfirmDiscard();
    } else {
      return;
    }
  };

  const handleClickDiscard = () => {
    const answer = confirm("Are you sure you want to discard all changes?");
    if (answer) {
      onConfirmDiscard();
    } else {
      return;
    }
  };

  if (!rows) {
    return <div>Loading...</div>;
  }

  return (
    <Box id={"hourly-layout"}>
      {isHourlyEnabled && (
        <Box>
          <Tooltip
            title={isHourlyEditDisabled ? "Cannot edit past data" : ""}
            followCursor
          >
            <Stack
              direction={"row"}
              alignItems={"center"}
              gap={2}
              id={"edit-toolbar"}
            >
              <FormControlLabel
                id={"edit-switch"}
                control={
                  <Switch
                    checked={isEditingHourly}
                    onChange={handleSwitchChange}
                    disabled={isHourlyEditDisabled}
                  />
                }
                label={"Edit Hourly Data"}
              />
              <Stack
                direction={"row"}
                alignItems={"center"}
                gap={2}
                id={"edit-buttons"}
              >
                <Button
                  variant="outlined"
                  disabled={editedRows.length === 0 || !isEditingHourly}
                  onClick={handleClickDiscard}
                  endIcon={<Delete />}
                >
                  Discard
                </Button>
                <Button
                  variant="contained"
                  color={"success"}
                  disabled={editedRows.length === 0 || !isEditingHourly}
                  onClick={onSaveButtonClicked}
                  endIcon={<Save />}
                >
                  Save
                </Button>
              </Stack>
              <HourlyEditToggleGroup />
            </Stack>
          </Tooltip>
        </Box>
      )}
      <Box display={"flex"} flexDirection={"column"} gap={"20px"}>
        <Box
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ flex: 1 }} id={"data-grid"}>
            {editType === "channel" && (
              <HourlyDataGrid
                rows={rows}
                setRows={setRows}
                setEditedRows={setEditedRows}
              />
            )}
            {editType === "total" && (
              <HourlyDataGridTotal
                rows={rows}
                setRows={setRows}
                setEditedRows={setEditedRows}
              />
            )}
          </Box>
          <HourlySidebar currentForecast={currentForecast} />
        </Box>
        <RestaurantEventsList />
      </Box>
      <HourlyEditDialog
        onConfirmSave={onConfirmSave}
        open={open}
        onClose={() => setOpen(false)}
        editedRows={editedRows}
      />
    </Box>
  );
};

export default HourlyLayout;
