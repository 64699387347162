import React from "react";
import { SvgIcon } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { ReactComponent as BrokenHeartSVG } from "../assets/broken-heart.svg";

const useStyles = makeStyles(() => ({
  root: {
    width: 80,
    height: 80,
  },
}));

const BrokenHeartIcon: React.FC = () => {
  const classes = useStyles();
  return (
    <SvgIcon component={BrokenHeartSVG} viewBox="0 0 80 80" classes={classes} />
  );
};

export default BrokenHeartIcon;
