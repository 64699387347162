import Box from "@mui/material/Box";
import React from "react";
import { HourlySummaryBox } from "./HourlySummaryBox";
import { HourlyHelpBox } from "./HourlyHelpBox";
import { TParams } from "../../../types";
import { useParams } from "react-router-dom";
import { useRestaurantTrial } from "../../../hooks/useRestaurantTrial/useRestaurantTrial";

type props = {
  currentForecast: number;
};

export const HourlySidebar = ({ currentForecast }: props) => {
  const { restaurantId } = useParams<TParams>();
  const { data: isHourlyEnabled } = useRestaurantTrial(
    restaurantId,
    "hourly_forecast_adjustments",
  );

  if (!isHourlyEnabled) {
    return null;
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        maxWidth: "300px",
        display: "flex",
        gap: "1rem",
        flexDirection: "column",
      }}
    >
      <HourlySummaryBox currentForecast={currentForecast} />
      <Box sx={{ flexShrink: 1 }}>
        <HourlyHelpBox />
      </Box>
    </Box>
  );
};
