import { useEffect, useState } from "react";
import { getForecastedDate } from "../../lib/getForecastedDate";
import { forecastMetadataType } from "../../types/schemas";

interface props {
  forecasts: forecastMetadataType[] | undefined;
}

export const useLastUpdatedDate = ({ forecasts }: props) => {
  const [dateLastUpdated, setDateLastUpdated] = useState<Date | null>(null);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    if (forecasts) {
      const date = getForecastedDate({ forecasts });
      if (date) {
        setDateLastUpdated(date);
      } else {
        setError(true);
      }
    }
  }, [forecasts]);

  return { dateLastUpdated, error };
};
