import React, { useEffect, useState } from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import NumberFormat, { InputAttributes } from "react-number-format";
import InputAdornment from "@mui/material/InputAdornment";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

type Value = string | undefined;

const NumberFormatCustom = React.forwardRef<
  NumberFormat<InputAttributes>,
  CustomProps
>(function NumberFormatCustom(props, ref) {
  // if inflation continues like this, we may need to increase this number
  const MAX_VAL = 100000;
  const withValueLimit = ({ value }: { value: Value }) => {
    const numericValue = value ? parseFloat(value) : undefined;

    //restricts max number to be entered and allows empty field
    return (
      numericValue === undefined ||
      (numericValue <= MAX_VAL && numericValue >= 0)
    );
  };

  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      isAllowed={withValueLimit}
    />
  );
});

interface ControlFieldProps {
  defaultValue?: number;
  currencySymbol?: string;
  handleChange: (value: number) => void;
}

const ControlField = ({
  defaultValue,
  currencySymbol,
  handleChange,
}: ControlFieldProps) => {
  const [value, setValue] = useState<undefined | number>(defaultValue);
  const helperText = null;

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(e.target.value));
    handleChange(Number(e.target.value));
  };

  useEffect(() => {
    handleChange(Number(value));
  }, [value]);

  return (
    <FormControl fullWidth>
      <TextField
        autoFocus
        id="outlined-required"
        label="Adjusted Total"
        defaultValue={value}
        helperText={helperText}
        onChange={handleValueChange}
        sx={{ input: { textAlign: "right" } }}
        InputProps={{
          // eslint-disable-next-line
          inputComponent: NumberFormatCustom as any,
          // TODO sort out currency and enable
          startAdornment: (
            <InputAdornment position="start">{currencySymbol}</InputAdornment>
          ),
        }}
      />
    </FormControl>
  );
};

export default ControlField;
