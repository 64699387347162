import React from "react";
import { useScript } from "../../hooks/useScript/useScript";
import configApi from "../../lib/configApi";

interface WoosMapProps {
  woosmapConfig: woosmap.map.MapOptions;
}

export const WoosmapMap: React.FC<WoosMapProps> = ({ woosmapConfig }) => {
  const mapRef = React.useRef<woosmap.map.Map>();

  const scriptLoadStatus = useScript(
    `https://sdk.woosmap.com/map/map.js?key=${configApi.woosmap.apiKey}`,
  );

  const mapElementRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (mapElementRef.current && scriptLoadStatus === "ready") {
      const map = new woosmap.map.Map(mapElementRef.current, woosmapConfig);

      const marker = new woosmap.map.Marker({
        position: map.getCenter(),
        icon: {
          url: "https://images.woosmap.com/dot-marker.png",
          scaledSize: {
            height: 32,
            width: 24,
          },
        },
      });

      marker.setMap(map);

      mapRef.current = map;
    }
  }, [scriptLoadStatus]);
  return <div ref={mapElementRef} style={{ width: "100%", height: "100%" }} />;
};
