import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useDateFromParams } from "../../../hooks/useDateFromParams/useDateFromParams";
import { format } from "date-fns";
import { useEventsData } from "../../../hooks/useEventsData/useEventsData";
import { Event } from "@mui/icons-material";
import Box from "@mui/material/Box";
import { RestaurantEventsListItem } from "./RestaurantEventsListItem";
import Typography from "@mui/material/Typography";
import { SummaryBox } from "./SummaryBox";
import { useRestaurantTrial } from "../../../hooks/useRestaurantTrial/useRestaurantTrial";

export const RestaurantEventsList = () => {
  const [searchParams] = useSearchParams();
  const { userChosenDate } = useDateFromParams({ searchParams });
  const { restaurantId } = useParams();

  const { data: shouldDisplayEvents } = useRestaurantTrial(
    restaurantId,
    "forecast_events_display",
  );

  const { data: events, isLoading } = useEventsData(
    format(userChosenDate, "yyyy-MM-dd"),
    restaurantId,
    shouldDisplayEvents,
  );

  if (isLoading) {
    return <p>loading...</p>;
  }

  if (!shouldDisplayEvents) {
    return null;
  }

  // This groups events by state, so that active events are shown first
  const groupedEvents = events.sort((a, b) => {
    if (a.state === b.state) return 0;
    if (a.state === "active" && b.state === "deleted") return -1;
    return 1;
  });

  return (
    <SummaryBox
      title={
        <Box sx={{ display: "flex", gap: "4px" }}>
          <Event />
          <Typography
            sx={{
              margin: 0,
              fontWeight: "bold",
            }}
          >
            Nearby Events
          </Typography>
        </Box>
      }
    >
      <Box>
        {groupedEvents.length === 0 && <p>No events today</p>}

        <Box>
          {groupedEvents.map((event, idx) => (
            <RestaurantEventsListItem event={event} key={idx} />
          ))}
        </Box>
      </Box>
    </SummaryBox>
  );
};
