import Box from "@mui/material/Box";
import RestaurantSelector from "../RestaurantSelector";
import ForecastDatePicker from "../ForecastDatePicker/ForecastDatePicker";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { useEditingContext } from "../../contexts/EditingContext";
import { GranularityDropdown } from "./GranularityDropdown";

export const UserParamsBar = () => {
  const { isEditingHourly } = useEditingContext();

  return (
    <Tooltip
      title={isEditingHourly ? "Disabled while editing" : ""}
      followCursor
    >
      <Box display={"flex"} gap={2}>
        <RestaurantSelector />
        <GranularityDropdown />
        <ForecastDatePicker />
      </Box>
    </Tooltip>
  );
};
