import { IHourlyForecastRows } from "../../types";
import { deepClone } from "@mui/x-data-grid/utils/utils";

const processHourlyRowChange = ({
  original,
  update,
}: {
  original: IHourlyForecastRows;
  update: IHourlyForecastRows;
}) => {
  const base = deepClone(original);

  const {
    collect: newCollect,
    eatIn: newEatIn,
    delivery: newDelivery,
  } = update;

  if (newCollect.isAdjusted) {
    return {
      ...base,
      collect: newCollect,
    };
  }

  if (newEatIn.isAdjusted) {
    return {
      ...base,
      eatIn: newEatIn,
    };
  }

  if (newDelivery.isAdjusted) {
    return {
      ...base,
      delivery: newDelivery,
    };
  }
};

export default processHourlyRowChange;
