import { useLastUpdatedDate } from "../../hooks/useLastUpdatedDate/useLastUpdatedDate";
import { forecastMetadataType } from "../../types/schemas";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import React from "react";
import { format } from "date-fns";

interface props {
  forecasts: forecastMetadataType[] | undefined;
}
export const LastUpdatedCopy = ({ forecasts }: props) => {
  const { dateLastUpdated, error: lastUpdatedError } = useLastUpdatedDate({
    forecasts,
  });

  if (lastUpdatedError) {
    return null;
  }

  if (!dateLastUpdated) {
    return (
      <Box>
        <Typography variant="h6">Loading last updated date...</Typography>
      </Box>
    );
  }

  return (
    <Box display={"flex"} alignItems={"center"} gap={1}>
      <Typography variant="body1">Data last updated:</Typography>
      <Typography variant={"body1"} sx={{ textDecoration: "underline" }}>
        {format(dateLastUpdated, "EEE dd/MM/yyyy @ HH:mm")}
      </Typography>
    </Box>
  );
};
