import React from "react";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Box from "@mui/material/Box";
import { makeStyles } from "@mui/styles";
import useUserInfo from "../lib/useUserInfo";
import LogRocket from "logrocket";
import Layout from "../components/Layout";
import RestaurantSelector from "../components/RestaurantSelector";

const useStyles = makeStyles({
  card: {
    display: "inline-block",
  },
});

const Restaurant: React.FC = () => {
  const classes = useStyles();
  const userInfo = useUserInfo();

  if (userInfo?.name && userInfo?.email) {
    LogRocket.identify(userInfo.sub, {
      name: userInfo.name,
      email: userInfo.email,
    });
  }

  return (
    <Layout>
      <Box marginTop={4} display={"flex"} justifyContent={"center"}>
        <Card className={classes.card}>
          <CardContent>
            <Typography variant={"h5"}>Select a restaurant</Typography>
            <Typography variant={"body2"}>
              View, and edit forecast data for a specific restaurant.
            </Typography>
          </CardContent>
          <CardActions>
            <RestaurantSelector />
          </CardActions>
        </Card>
      </Box>
    </Layout>
  );
};

export default Restaurant;
