import { max } from "date-fns";
import * as Sentry from "@sentry/react";
import z from "zod";
import { forecastMetadataType } from "../types/schemas";

interface args {
  forecasts: forecastMetadataType[];
}
export const getForecastedDate = ({ forecasts }: args) => {
  try {
    const forecastedDates = forecasts.reduce<Date[]>((acc, forecast) => {
      const isValidDate = z.date().safeParse(forecast.forecastedDate);
      if (isValidDate.success) {
        acc.push(isValidDate.data);
      }
      return acc;
    }, []);

    if (!forecastedDates.length) {
      return null;
    }

    return max(forecastedDates);
  } catch (error) {
    Sentry.captureException(error);
    return null;
  }
};
