import config from "./configApi";

export const sendFeedbackToSlack = async (feedback: string) => {
  const token = config.slack.token;
  const channel = config.slack.channel;

  if (!token || !channel) {
    throw new Error("Missing Environment Variables");
  }

  const today = new Date();
  const attachments = JSON.stringify([
    { pretext: `Feedback | ${today}`, text: feedback },
  ]);

  const params = new URLSearchParams({
    channel,
    attachments,
    token,
  });

  const postMessageResponse = await fetch(
    `https://slack.com/api/chat.postMessage`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: params,
    },
  );

  if (!postMessageResponse.ok) {
    throw new Error("Failed to send feedback to slack");
  }

  const data = await postMessageResponse.json();

  if (data.error) {
    throw new Error(data.error);
  }

  return data;
};
