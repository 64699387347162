import { IFineGrainValue, IHourlyForecastRows } from "../../types";
import sumBy from "lodash/sumBy";

interface props {
  hourlyRows: IHourlyForecastRows[];
}

interface channelValues {
  valueWithoutAdjustments: number;
  totalAdjustments: number;
  valueWithAdjustments: number;
}

interface hourlyChannelTotals {
  eatIn: channelValues;
  delivery: channelValues;
  collect: channelValues;
}
export const getHourlyChannelTotals = ({
  hourlyRows,
}: props): hourlyChannelTotals => {
  const hourlyChannelTotals = {
    eatIn: {
      valueWithoutAdjustments: 0,
      totalAdjustments: 0,
      valueWithAdjustments: 0,
    },
    delivery: {
      valueWithoutAdjustments: 0,
      totalAdjustments: 0,
      valueWithAdjustments: 0,
    },
    collect: {
      valueWithoutAdjustments: 0,
      totalAdjustments: 0,
      valueWithAdjustments: 0,
    },
  };

  const eatInValues = hourlyRows.map((row) => row.eatIn);
  hourlyChannelTotals.eatIn = getTotalsFromFineGrainedValues({
    fineGrainedValues: eatInValues,
  });

  const deliveryValues = hourlyRows.map((row) => row.delivery);
  hourlyChannelTotals.delivery = getTotalsFromFineGrainedValues({
    fineGrainedValues: deliveryValues,
  });

  const collectValues = hourlyRows.map((row) => row.collect);
  hourlyChannelTotals.collect = getTotalsFromFineGrainedValues({
    fineGrainedValues: collectValues,
  });

  return hourlyChannelTotals;
};

const getTotalsFromFineGrainedValues = ({
  fineGrainedValues,
}: {
  fineGrainedValues: IFineGrainValue[];
}) => {
  const valueWithoutAdjustments = sumBy(fineGrainedValues, (value) => {
    return value.originalValue;
  });

  const totalAdjustments = sumBy(fineGrainedValues, (value) => {
    return value.offsetValue;
  });

  const valueWithAdjustments = valueWithoutAdjustments + totalAdjustments;

  return {
    valueWithoutAdjustments,
    totalAdjustments,
    valueWithAdjustments,
  };
};
