import { useState, useEffect } from "react";
import { UserClaims } from "@okta/okta-auth-js";
import { useOktaAuth } from "@okta/okta-react";

const useUserInfo = (): UserClaims | null => {
  const [userInfo, setUserInfo] = useState<UserClaims | null>(null);
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState?.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null);
    } else {
      oktaAuth.getUser().then((info) => {
        if (info) {
          setUserInfo(info);
        }
      });
    }
  }, [authState, oktaAuth]);

  return userInfo;
};

export default useUserInfo;
