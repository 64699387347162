import { z } from "zod";
interface props {
  searchParams: URLSearchParams;
}
export const useDateFromParams = ({ searchParams }: props) => {
  const userChosenDate = searchParams.get("date");

  try {
    const date = z.coerce
      .date()
      .nullable()
      .transform((date) => {
        if (date === null) {
          return new Date();
        }
        return date;
      })
      .parse(userChosenDate);

    return { userChosenDate: date };
  } catch (error) {
    return { userChosenDate: new Date() };
  }
};
