import { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";
import { Reason } from "../../../types";

const HourlyReasonsInput = ({
  reasons,
  selectedReason,
  handleReasonSelection,
}: {
  reasons: Reason[];
  selectedReason: string;
  handleReasonSelection: (value: string) => void;
}) => {
  const handleSelectReason = (event: SelectChangeEvent) => {
    const { value } = event.target;
    handleReasonSelection(value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="reason-select-label">Adjustment reason</InputLabel>
      <Select
        defaultValue={""}
        label="Adjustment reason"
        labelId="reason-select-label"
        id="reason-select"
        onChange={handleSelectReason}
        value={selectedReason}
        inputMode={"numeric"}
        data-testid="reason-select"
      >
        {reasons
          .filter((x) => x.is_enabled)
          .sort((a, b) => a.description.localeCompare(b.description))
          .map(({ id, description }, index) => {
            if (id === 14) return;
            return (
              <MenuItem key={index} value={`${id}`}>
                {description}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
};

export default HourlyReasonsInput;
