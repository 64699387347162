import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { SummaryBox } from "./SummaryBox";

type props = {
  currentForecast: number;
};
export const HourlySummaryBox = ({ currentForecast }: props) => {
  return (
    <SummaryBox title={"Summary"}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "0.5rem",
        }}
      >
        <Typography variant={"subtitle1"} sx={{ textDecoration: "underline" }}>
          Current Forecast
        </Typography>
        <Typography variant={"body2"}>
          {currentForecast.toLocaleString(undefined, {
            currency: "GBP",
            style: "currency",
            maximumFractionDigits: 0,
          })}
        </Typography>
      </Box>
    </SummaryBox>
  );
};
