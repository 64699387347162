import { z } from "zod";
import { Granularity } from "./index";

const defaultDate = z
  .string()
  .datetime()
  .nullable()
  .transform((dateString) => {
    if (dateString) {
      return new Date(dateString);
    }
    return new Date(2022, 1, 4);
  })
  .pipe(z.date());

const adjustment = z.object({
  adjustmentId: z.number(),
  adjustment_createdAt: defaultDate,
  adjustment_createdBy: z.string().email(),
  adjustment_otherReason: z.string().nullable(),
  adjustment_reasonId: z.number(),
  businessDate: defaultDate,
  fk_forecastId: z.number(),
  multiplier: z.number(),
  restaurantId: z.string(),
  rebaseline: z.boolean(),
  previousMultiplier: z.number(),
  latestUserAdjustmentId: z.number(),
  latestAdjustedForecastId: z.number(),
  percentageChange: z.number(),
  showAlert: z.boolean(),
});

const forecast = z.object({
  forecastId: z.number(),
  businessDate: defaultDate,
  eatIn: z.number().nullable(),
  delivery: z.number().nullable(),
  collect: z.number().nullable(),
  total: z.number(),
});

const hourlyAdjustment = z.object({
  business_time: defaultDate,
  cost_centre: z.string(),
  channel: z.enum(["eatIn", "delivery", "collect"]),
  value: z.number(),
  forecast_id: z.number(),
  createdBy: z.string().email(),
});

const sales = z.object({
  salesId: z.number(),
  businessDate: defaultDate,
  actual_eatIn: z.number(),
  actual_delivery: z.number(),
  actual_collect: z.number(),
  actual_total: z.number(),
});

const forecastMetadata = z.object({
  forecastID: z.number(),
  forecastedDate: defaultDate,
  rowCount: z
    .number()
    .nullable()
    .transform((value) => (value === null ? 0 : value)),
  createdAt: defaultDate,
  fileName: z
    .string()
    .nullable()
    .transform((value) => value ?? "no_forecast_file_name.csv"),
});

const salesMetadata = z.object({
  salesID: z.number(),
  salesDate: defaultDate,
  rowCount: z
    .number()
    .nullable()
    .transform((value) => (value === null ? 0 : value)),
  createdAt: defaultDate,
  fileName: z
    .string()
    .nullable()
    .transform((value) => value ?? "no_sales_file_name.csv"),
});

const importMetadata = z.object({
  forecasts: z.array(forecastMetadata),
  sales: z.array(salesMetadata),
});

const variances = z.object({
  varianceId: z.number(),
  businessDate: defaultDate,
  restaurantId: z.string(),
  totalForecast: z.number(),
  totalSales: z.number(),
  variance_reasonId: z.any(),
  variance_otherReason: z.string().nullable(),
  variance_createdBy: z.string().email(),
  variance_createdAt: defaultDate,
});

export const combinedDataAPIResponse = z.object({
  adjustments: z.array(adjustment),
  forecasts: z.array(forecast),
  hourlyAdjustments: z.array(hourlyAdjustment),
  sales: z.array(sales),
  import_metadata: importMetadata,
  variances: z.array(variances),
});

export type combinedDataAPIResponseType = z.infer<
  typeof combinedDataAPIResponse
>;

export type forecastMetadataType = z.infer<typeof forecastMetadata>;

export const GranularitySchema = z.custom<Granularity>((input) => {
  if (input === "Hourly" || input === "Daily" || input === "Weekly") {
    return input;
  } else {
    return false;
  }
});
