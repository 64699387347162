import Dashboard from "./Dashboard";
import React from "react";
import { EditingProvider } from "../contexts/EditingContext";
import { FeatureFlagsProvider } from "../contexts/FeatureFlagsContext";
import { ThemeProvider } from "@mui/styles";
import { createTheme } from "@mui/material/styles";
const DashboardWithProviders = () => {
  const theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <EditingProvider>
        <FeatureFlagsProvider>
          <Dashboard />
        </FeatureFlagsProvider>
      </EditingProvider>
    </ThemeProvider>
  );
};

export default DashboardWithProviders;
