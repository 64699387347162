import gql from "graphql-tag";

const CREATE_ADJUSTMENT = gql`
  mutation Mutation(
    $businessDate: Date!
    $restaurantId: String!
    $multiplier: Float!
    $forecastId: Int!
    $adjustment_reasonId: Int!
    $adjustment_otherReason: String!
  ) {
    create_adjustment(
      business_date: $businessDate
      cost_centre: $restaurantId
      multiplier: $multiplier
      forecast_id: $forecastId
      reason_id: $adjustment_reasonId
      other_reason: $adjustment_otherReason
    ) {
      business_date
      multiplier
      cost_centre
      forecast_id
      createdBy: created_by
      createdAt: created_at
      reason_id
      other_reason
    }
  }
`;

const CREATE_HOURLY_ADJUSTMENT = gql`
  mutation Mutation(
    $hourlyEdits: [HourlyEdit]
    $restaurantId: String!
    $forecastId: Int!
    $adjustment_reasonId: Int!
    $adjustment_otherReason: String
  ) {
    create_hourly_adjustment(
      hourly_edits: $hourlyEdits
      cost_centre: $restaurantId
      forecast_id: $forecastId
      reason_id: $adjustment_reasonId
      other_reason: $adjustment_otherReason
    ) {
      business_time
      cost_centre
      channel
      value
      forecast_id
      createdBy: created_by
      createdAt: created_at
      reason_id
      other_reason
      __typename
    }
  }
`;

const CREATE_VARIANCE = gql`
  mutation Mutation(
    $businessDate: Date!
    $restaurantId: String!
    $totalForecast: Float!
    $totalSales: Float!
    $variance_reasonId: Int!
    $variance_otherReason: String!
  ) {
    create_variance(
      business_date: $businessDate
      cost_centre: $restaurantId
      total_forecast: $totalForecast
      total_sales: $totalSales
      reason_id: $variance_reasonId
      other_reason: $variance_otherReason
    ) {
      business_date
      cost_centre
      total_forecast
      total_sales
      reason_id
      other_reason
    }
  }
`;

const GET_DATE_RANGE = gql`
  query date_range($restaurantId: String!) {
    date_range(cost_centre: $restaurantId) {
      start
      end
    }
  }
`;

export const GET_OPENING_HOURS = gql`
  query opening_hours($cost_centre: String!, $date: Date!) {
    opening_hours(cost_centre: $cost_centre, date: $date) {
      open_time
      close_time
    }
  }
`;

const GET_COMBINED_DATA = gql`
  query combined_data(
    $restaurantId: String!
    $dateStart: Date!
    $dateEnd: Date!
    $granularity: String
  ) {
    hourlyAdjustments(
      cost_centre: $restaurantId
      date_start: $dateStart
      date_end: $dateEnd
    ) {
      business_time
      cost_centre
      channel
      value
      forecast_id
      createdBy: created_by
    }
    adjustments(
      cost_centre: $restaurantId
      date_start: $dateStart
      date_end: $dateEnd
    ) {
      adjustmentId: adjustment_id
      fk_forecastId: forecast_id
      businessDate: business_date
      restaurantId: cost_centre
      multiplier
      adjustment_reasonId: reason_id
      adjustment_otherReason: other_reason
      adjustment_createdBy: created_by
      adjustment_createdAt: created_at
      rebaseline
      previousMultiplier: previous_multiplier
      latestUserAdjustmentId: latest_user_adjustment_id
      latestAdjustedForecastId: latest_adjusted_forecast_id
      percentageChange: percentage_change
      showAlert: show_alert
    }
    forecasts(
      cost_centre: $restaurantId
      date_start: $dateStart
      date_end: $dateEnd
      granularity: $granularity
    ) {
      forecastId: forecast_id
      businessDate: business_date
      eatIn: eat_in_forecast
      delivery: delivery_forecast
      collect: collect_forecast
      total: total_forecast
    }
    import_metadata {
      forecasts {
        forecastID: id
        forecastedDate: file_date
        rowCount: row_count
        createdAt: created_at
        fileName: file_name
      }
      sales {
        salesID: id
        salesDate: file_date
        rowCount: row_count
        createdAt: created_at
        fileName: file_name
      }
    }
    sales(
      cost_centre: $restaurantId
      date_start: $dateStart
      date_end: $dateEnd
      granularity: $granularity
    ) {
      salesId: sales_id
      businessDate: business_date
      actual_eatIn: eat_in_sales
      actual_delivery: delivery_sales
      actual_collect: collect_sales
      actual_total: total_sales
    }
    variances(
      cost_centre: $restaurantId
      date_start: $dateStart
      date_end: $dateEnd
    ) {
      varianceId: variance_id
      businessDate: business_date
      restaurantId: cost_centre
      totalForecast: total_forecast
      totalSales: total_sales
      variance_reasonId: reason_id
      variance_otherReason: other_reason
      variance_createdBy: created_by
      variance_createdAt: created_at
    }
  }
`;

const GET_ADJUSTMENT_REASONS = gql`
  query adjustment_reasons_old {
    adjustment_reasons {
      id
      description
      is_enabled
    }
  }
`;

const GET_ADJUSTMENT_REASONS2 = gql`
  query adjustment_reasons {
    reasons: adjustment_reasons {
      id
      description
      is_enabled
    }
  }
`;

const GET_VARIANCE_REASONS = gql`
  query variance_reasons {
    reasons: variance_reasons {
      id
      description
      is_enabled
    }
  }
`;

export {
  CREATE_ADJUSTMENT,
  CREATE_HOURLY_ADJUSTMENT,
  CREATE_VARIANCE,
  GET_ADJUSTMENT_REASONS,
  GET_ADJUSTMENT_REASONS2,
  GET_DATE_RANGE,
  GET_COMBINED_DATA,
  GET_VARIANCE_REASONS,
};
