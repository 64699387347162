import { IHourlyEditDiff } from "../../types";

export const ProcessHourlyEdits = (
  rows: IHourlyEditDiff[],
  reasonId: number,
  otherReason: string,
) => {
  interface HourlyEdits {
    channel: string;
    business_time: Date;
    value: number;
  }

  const hourlyEdits: HourlyEdits[] = [];

  for (const row of rows) {
    if (row.channelOffset.collect !== null) {
      hourlyEdits.push({
        channel: "collect",
        business_time: row.dataToPost.businessTime,
        value: row.channelOffset.collect,
      });
    }
    if (row.channelOffset.eatIn !== null) {
      hourlyEdits.push({
        channel: "eatIn",
        business_time: row.dataToPost.businessTime,
        value: row.channelOffset.eatIn,
      });
    }
    if (row.channelOffset.delivery !== null) {
      hourlyEdits.push({
        channel: "delivery",
        business_time: row.dataToPost.businessTime,
        value: row.channelOffset.delivery,
      });
    }
  }

  const adjustment_otherReason = reasonId === 13 ? otherReason : null;

  return {
    forecastId: rows[0].dataToPost.forecastId,
    restaurantId: rows[0].dataToPost.restaurantId,
    adjustment_reasonId: reasonId,
    adjustment_otherReason,
    hourlyEdits,
  };
};
