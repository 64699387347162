import * as React from "react";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import isSameDay from "date-fns/isSameDay";
import isWithinInterval from "date-fns/isWithinInterval";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import addsWeeks from "date-fns/addWeeks";
import enGb from "date-fns/locale/en-GB";
import { dateAsUTC } from "../../lib/getForecastDataRecords";
import { format, setDefaultOptions, startOfDay } from "date-fns";
import { useEditingContext } from "../../contexts/EditingContext";
import { InfoRounded } from "@mui/icons-material";
import Box from "@mui/material/Box";
import config from "../../lib/configApi";
import { useSearchParams } from "react-router-dom";
import { useDateFromParams } from "../../hooks/useDateFromParams/useDateFromParams";
import { useGranularityFromParams } from "../../hooks/useGranularityFromParams/useGranularityFromParams";

const ForecastDatePicker = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { isEditingHourly } = useEditingContext();
  const { userChosenDate } = useDateFromParams({ searchParams });

  const { granularity, weeksSelected } = useGranularityFromParams({
    searchParams,
  });

  const startOfToday = startOfDay(dateAsUTC(new Date()));
  const weeksInTheFuture = addsWeeks(
    startOfToday,
    config.forecast.weeksInFuture,
  );
  const weeksInThePast = addsWeeks(startOfToday, -config.forecast.weeksInPast);

  setDefaultOptions({ weekStartsOn: 1 });

  const renderWeekPickerDay = (
    date: Date,
    selectedDates: Array<Date | null>,
    pickersDayProps: PickersDayProps<Date>,
  ) => {
    if (granularity === "Hourly" || !userChosenDate) {
      return <PickersDay {...pickersDayProps} />;
    }

    const start = startOfWeek(userChosenDate, { locale: enGb });
    const end = addsWeeks(
      endOfWeek(userChosenDate, { locale: enGb }),
      weeksSelected - 1,
    );

    const dayIsBetween = isWithinInterval(date, { start, end });
    const isFirstDay = isSameDay(date, start);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  const handleDateChange = (newDate: Date | null) => {
    if (!newDate) return;
    setSearchParams((prevParams) => {
      prevParams.set("date", format(newDate, "yyyy-MM-dd"));
      return prevParams;
    });
  };

  const start = startOfWeek(userChosenDate, { locale: enGb, weekStartsOn: 1 });

  const inputFormat =
    granularity !== "Hourly"
      ? `'Week of ${format(start, "dd/MM/yyyy")}'`
      : "dd/MM/yyyy";

  const inputLabel = granularity === "Hourly" ? "Date picker" : "Week picker";

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={inputLabel}
        value={userChosenDate}
        disabled={isEditingHourly}
        onChange={handleDateChange}
        renderInput={(params) => <TextField {...params} />}
        minDate={weeksInThePast}
        maxDate={weeksInTheFuture}
        renderDay={renderWeekPickerDay}
        inputFormat={inputFormat}
        disableMaskedInput={true}
        showDaysOutsideCurrentMonth={false}
        components={{
          ActionBar: () => (
            <Box
              display={"flex"}
              alignItems={"center"}
              width={"320px"}
              gap={"0.5rem"}
              padding={"0.5rem"}
            >
              <InfoRounded />
              <span style={{ fontSize: 14 }}>
                You can only view forecasts from the past{" "}
                {config.forecast.weeksInPast} weeks and the next{" "}
                {config.forecast.weeksInFuture} weeks.
              </span>
            </Box>
          ),
        }}
      />
    </LocalizationProvider>
  );
};

export default ForecastDatePicker;

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    borderTop: "1px solid #A51428",
    borderBottom: "1px solid #A51428",
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
    borderLeft: "1px solid #A51428",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
    borderRight: "1px solid #A51428",
  }),
})) as React.ComponentType<CustomPickerDayProps>;

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean;
  isFirstDay: boolean;
  isLastDay: boolean;
};
