import React, { createContext, ReactNode, useContext, useState } from "react";
import { isBetaUser } from "../features";

interface FeatureFlagsContextType {
  isHourlyAdjustmentEnabled: boolean;
  isBetaUser: boolean;
  setIsHourlyAdjustmentEnabled: (enabled: boolean) => void;
}

const FeatureFlagsContext = createContext<FeatureFlagsContextType>({
  isHourlyAdjustmentEnabled: false,
  isBetaUser: false,
  setIsHourlyAdjustmentEnabled: () => {
    return true;
  },
});

const FeatureFlagsProvider = ({ children }: { children: ReactNode }) => {
  const [isHourlyAdjustmentEnabled, setIsHourlyAdjustmentEnabled] =
    useState<boolean>(false);
  const betaUser = isBetaUser();

  return (
    <FeatureFlagsContext.Provider
      value={{
        isHourlyAdjustmentEnabled,
        setIsHourlyAdjustmentEnabled,
        isBetaUser: betaUser,
      }}
    >
      {children}
    </FeatureFlagsContext.Provider>
  );
};

const useFeatureFlagsContext = () => useContext(FeatureFlagsContext);
export { FeatureFlagsProvider, useFeatureFlagsContext };
