import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
  },
  toolbar: {
    paddingRight: 20,
    minHeight: "56px",
  },
  menuButton: {
    margin: "0 -4px 0 8px",
  },
});

export interface AppBarProps {
  children?: React.ReactNode;
  onMenuClick?: () => void;
}

const AppBar: React.FC<AppBarProps> = ({ children, onMenuClick }) => {
  const classes = useStyles();

  const backgroundColor = "#383838";

  return (
    <MuiAppBar
      position="static"
      className={classes.root}
      style={{ backgroundColor }}
    >
      <Toolbar disableGutters variant="regular" className={classes.toolbar}>
        {onMenuClick ? (
          <IconButton
            color="inherit"
            aria-label="home"
            className={classes.menuButton}
            onClick={onMenuClick}
            size="large"
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        {children}
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
