import formatNumber from "../../../lib/formatNumber";
import { Tooltip } from "@mui/material";
import React from "react";

interface props {
  originalValue: number;
  offsetValue: number;
}

const HourlyEditCell = ({ originalValue, offsetValue }: props) => {
  const number =
    originalValue + offsetValue <= 0 ? 1 : originalValue + offsetValue;

  if (offsetValue === 0) return <p>{formatNumber(originalValue)}</p>;

  const title = (
    <React.Fragment>
      <div style={{ textAlign: "right" }}>
        <p>Forecasted: {" " + formatNumber(originalValue)}</p>
        <p>
          Adjustment: {offsetValue > 0 && "+"}
          {formatNumber(offsetValue)}
        </p>
        <div
          style={{ height: 1, width: "100%", backgroundColor: "white" }}
        ></div>
        <p>{formatNumber(originalValue + offsetValue)}</p>
      </div>
    </React.Fragment>
  );

  return (
    <Tooltip title={title} disableFocusListener={true}>
      <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
        {formatNumber(number)}
      </p>
    </Tooltip>
  );
};

export default HourlyEditCell;
