import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Link } from "react-router-dom";
import BaseErrorPage from "../components/BaseErrorPage";
import BrokenHeartIcon from "../components/Icons/BrokenHeartIcon";
import Layout from "../components/Layout";
import { staticRoutes } from "../routes";

const useStyles = makeStyles((theme) => ({
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    textDecoration: "underline",
  },
}));

const PageNotFound: React.FC = () => {
  const classes = useStyles();

  return (
    <Layout>
      <BaseErrorPage
        icon={<BrokenHeartIcon />}
        heading="404 - Page not found"
        bodyPrimary="We’re sorry, but the page you requested has not been found."
        bodySecondary={
          <Link className={classes.link} to={staticRoutes.index}>
            Back to restaurant homepage
          </Link>
        }
      />
    </Layout>
  );
};
export default PageNotFound;
