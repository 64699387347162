import ForecastDataGrid from "../ForecastDataGrid";
import React, { useState } from "react";
import { GridColDef, GridRowParams } from "@mui/x-data-grid";
import formatNumber from "../../../../lib/formatNumber";
import endOfDay from "date-fns/endOfDay";
import { deepClone } from "@mui/x-data-grid/utils/utils";
import { useEditingContext } from "../../../../contexts/EditingContext";
import {
  IFineGrainValue,
  IHourlyEditDiff,
  IHourlyForecastRows,
  IUpdateHourlyForecastRows,
} from "../../../../types";
import sumBy from "lodash/sumBy";
import mergeWith from "lodash/mergeWith";
import HourlyEditCell from "../HourlyEditCell";
import PersonIcon from "@mui/icons-material/Person";
import { useFeatureFlagsContext } from "../../../../contexts/FeatureFlagsContext";
import { getHourlyChannelTotals } from "../../../../lib/getHourlyChannelTotals/getHourlyChannelTotals";
import { channelsFromRowUpdate } from "./channelsFromRowUpdate";

const HourlyDataGrid = ({
  rows,
  setRows,
  setEditedRows,
  ...props
}: {
  rows: IHourlyForecastRows[];
  setRows: React.Dispatch<React.SetStateAction<IHourlyForecastRows[]>>;
  setEditedRows: React.Dispatch<React.SetStateAction<IHourlyEditDiff[]>>;
}) => {
  const totals = getHourlyChannelTotals({ hourlyRows: rows });
  const { isEditingHourly } = useEditingContext();
  const { isBetaUser } = useFeatureFlagsContext();

  const columnDefinition: GridColDef[] = [
    {
      field: "id",
      sortable: false,
    },
    {
      field: "forecastId",
    },
    {
      field: "formattedBusinessDate",
      headerName: "Time",
      sortable: false,
    },
    {
      field: "businessDate",
      headerName: "Raw-Date",
    },
    {
      field: "eatIn",
      headerName: "Eat-In",
      description: "Eat-In forecast",
      type: "number",
      sortable: false,
      renderCell: ({ row }) => {
        if (row.isTotalRow)
          return (
            <HourlyEditCell
              originalValue={totals.eatIn.valueWithoutAdjustments}
              offsetValue={totals.eatIn.totalAdjustments}
            />
          );

        const { originalValue, isAdjusted, offsetValue } =
          row.eatIn as IFineGrainValue;

        if (isAdjusted)
          return (
            <HourlyEditCell
              originalValue={originalValue}
              offsetValue={offsetValue}
            />
          );

        return formatNumber(originalValue);
      },
    },
    {
      field: "delivery",
      headerName: "Delivery",
      description: "Delivery forecast",
      type: "number",
      renderCell: ({ row }) => {
        if (row.isTotalRow)
          return (
            <HourlyEditCell
              originalValue={totals.delivery.valueWithoutAdjustments}
              offsetValue={totals.delivery.totalAdjustments}
            />
          );
        const { originalValue, isAdjusted, offsetValue } =
          row.delivery as IFineGrainValue;

        if (isAdjusted)
          return (
            <HourlyEditCell
              originalValue={originalValue}
              offsetValue={offsetValue}
            />
          );

        return formatNumber(originalValue);
      },
    },
    {
      field: "collect",
      headerName: "Collect",
      description: "Collect forecast",
      type: "number",
      renderCell: ({ row }) => {
        if (row.isTotalRow)
          return (
            <HourlyEditCell
              originalValue={totals.collect.valueWithoutAdjustments}
              offsetValue={totals.collect.totalAdjustments}
            />
          );
        const { originalValue, isAdjusted, offsetValue } =
          row.collect as IFineGrainValue;

        if (isAdjusted)
          return (
            <HourlyEditCell
              originalValue={originalValue}
              offsetValue={offsetValue}
            />
          );

        return formatNumber(originalValue);
      },
    },
    {
      field: "total",
      headerName: "Total",
      description: "Total forecast",
      type: "number",
      editable: isEditingHourly,
      valueGetter: ({ row }) => {
        if (row.isTotalRow) {
          const { collect, eatIn, delivery } = totals;

          const totalOffset =
            collect.totalAdjustments +
            eatIn.totalAdjustments +
            delivery.totalAdjustments;

          const originalValue = row.total - totalOffset;

          return Number(originalValue).toFixed(0);
        }

        const collectTotal = row.collect.isAdjusted
          ? row.collect.offsetValue + row.collect.originalValue
          : row.collect.originalValue;
        const deliveryTotal = row.delivery.isAdjusted
          ? row.delivery.offsetValue + row.delivery.originalValue
          : row.delivery.originalValue;
        const eatInTotal = row.eatIn.isAdjusted
          ? row.eatIn.offsetValue + row.eatIn.originalValue
          : row.eatIn.originalValue;

        return Number(collectTotal + deliveryTotal + eatInTotal).toFixed(0);
      },
      renderCell: ({ row }) => {
        if (row.isTotalRow) {
          const { collect, eatIn, delivery } = totals;

          const totalOffset =
            collect.totalAdjustments +
            eatIn.totalAdjustments +
            delivery.totalAdjustments;

          const originalValue = row.total - totalOffset;

          return (
            <HourlyEditCell
              originalValue={originalValue}
              offsetValue={totalOffset}
            />
          );
        }

        const collectTotal = row.collect.isAdjusted
          ? row.collect.offsetValue + row.collect.originalValue
          : row.collect.originalValue;
        const deliveryTotal = row.delivery.isAdjusted
          ? row.delivery.offsetValue + row.delivery.originalValue
          : row.delivery.originalValue;
        const eatInTotal = row.eatIn.isAdjusted
          ? row.eatIn.offsetValue + row.eatIn.originalValue
          : row.eatIn.originalValue;
        const grandTotal = collectTotal + deliveryTotal + eatInTotal;

        const totalOffset =
          row.collect.offsetValue +
          row.delivery.offsetValue +
          row.eatIn.offsetValue;

        return (
          <HourlyEditCell
            originalValue={grandTotal - totalOffset}
            offsetValue={totalOffset}
          />
        );
      },
    },
    {
      field: "actual_total",
      headerName: "Actual",
      description: "Actual sales",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.actual_total),
      renderCell: ({ row }) => {
        if (row.isTotalRow)
          return <HourlyEditCell originalValue={row.actual} offsetValue={0} />;
      },
    },
    {
      field: "isAdjusted",
      headerName: "",
      renderCell: ({ row }) => {
        if (
          row.eatIn.isAdjusted ||
          row.collect.isAdjusted ||
          row.delivery.isAdjusted
        ) {
          return <PersonIcon />;
        } else return null;
      },
    },
  ];

  const [totalRow, setTotalRow] = useState<unknown>(() => {
    if (rows.length === 0) return {};

    const totalEatIn = sumBy(rows, (row: IHourlyForecastRows) => {
      const { isAdjusted, offsetValue, originalValue } = row.eatIn;

      if (isAdjusted) return offsetValue + originalValue;
      return originalValue;
    });
    const totalDelivery = sumBy(rows, (row: IHourlyForecastRows) => {
      const { isAdjusted, offsetValue, originalValue } = row.delivery;

      if (isAdjusted) return offsetValue + originalValue;
      return originalValue;
    });
    const totalCollect = sumBy(rows, (row: IHourlyForecastRows) => {
      const { isAdjusted, offsetValue, originalValue } = row.collect;

      if (isAdjusted) return offsetValue + originalValue;
      return originalValue;
    });

    const totalActual = sumBy(
      rows,
      (row: IHourlyForecastRows) => row.actual_total!,
    );

    const total = totalCollect + totalDelivery + totalEatIn;

    return {
      id: rows.length,
      collect: totalCollect,
      delivery: totalDelivery,
      eatIn: totalEatIn,
      actual: totalActual,
      total: total,
      isTotalRow: true,
      businessDate: endOfDay(rows[0].businessDate),
    };
  });

  const handleRowUpdate = (
    update: IHourlyForecastRows,
    original: IHourlyForecastRows,
  ) => {
    const channels = channelsFromRowUpdate({
      updatedRow: update,
      originalRow: original,
    });

    if (!channels) return original;
    const { eatInOffset, collectOffset, deliveryOffset } = channels;

    setTotalRow((previousTotalRow: IUpdateHourlyForecastRows) => {
      const newEatIn =
        eatInOffset !== 0
          ? (previousTotalRow.eatIn as number) +
            eatInOffset -
            original.eatIn.offsetValue
          : (previousTotalRow.eatIn as number);

      const newDelivery =
        deliveryOffset !== 0
          ? (previousTotalRow.delivery as number) +
            deliveryOffset -
            original.delivery.offsetValue
          : (previousTotalRow.delivery as number);

      const newCollect =
        collectOffset !== 0
          ? (previousTotalRow.collect as number) +
            collectOffset -
            original.collect.offsetValue
          : (previousTotalRow.collect as number);

      const newTot = {
        ...previousTotalRow,
        eatIn: newEatIn,
        delivery: newDelivery,
        collect: newCollect,
        total: newCollect + newDelivery + newEatIn,
      };
      return newTot;
    });

    const diffObject: IHourlyEditDiff = {
      id: update.id,
      originalEatIn: original.eatIn.originalValue,
      originalDelivery: original.delivery.originalValue,
      originalCollect: original.collect.originalValue,
      businessDate: update.formattedBusinessDate,
      channelOffset: {
        eatIn: eatInOffset,
        delivery: deliveryOffset,
        collect: collectOffset,
      },
      dataToPost: {
        forecastId: update.forecastId,
        businessTime: update.businessDate,
        restaurantId: update.restaurantId,
      },
    };

    const newUpdateRow: IHourlyForecastRows = {
      ...update,
      eatIn: {
        originalValue: original.eatIn.originalValue,
        isAdjusted: eatInOffset !== 0,
        offsetValue: eatInOffset !== 0 ? eatInOffset : 0,
      },
      delivery: {
        originalValue: original.delivery.originalValue,
        isAdjusted: deliveryOffset !== 0,
        offsetValue: deliveryOffset !== 0 ? deliveryOffset : 0,
      },
      collect: {
        originalValue: original.collect.originalValue,
        isAdjusted: collectOffset !== 0,
        offsetValue: collectOffset !== 0 ? collectOffset : 0,
      },
    };

    setEditedRows((prev: IHourlyEditDiff[]) => {
      // if the row is already in the array, we need to update it
      const index = prev.findIndex((row) => row.id === update.id);

      if (index !== -1) {
        const previousEdit = prev[index];
        const previousChannelOffset = previousEdit.channelOffset;

        const newChannelOffset = mergeWith(
          previousChannelOffset,
          diffObject.channelOffset,
          (objValue, srcValue) => {
            if (objValue === null) return srcValue;
            if (srcValue === null) return objValue;
            return srcValue;
          },
        );

        const newDiffObject = {
          ...previousEdit,
          channelOffset: newChannelOffset,
        };

        prev[index] = newDiffObject;

        return prev;
      }

      // if the row isn't in the array, we need to add it
      return [...prev, diffObject];
    });

    setRows((rows) => {
      const newRows = deepClone(rows);
      const index = newRows.findIndex(
        (row: IHourlyForecastRows) => row.id === update.id,
      );

      newRows[index] = newUpdateRow;

      return newRows;
    });

    return newUpdateRow;
  };

  return (
    <ForecastDataGrid
      columns={columnDefinition}
      rows={[totalRow, ...rows]}
      lastUpdated={new Date()}
      onProcessRowUpdateError={(error) => {
        // eslint-disable-next-line no-console
        console.log("error", error);
      }}
      initialState={{
        columns: {
          columnVisibilityModel: {
            id: false,
            forecastId: false,
            businessDate: false,
          },
        },
      }}
      getRowClassName={(params: GridRowParams) => {
        if (params.row.isTotalRow) return "total";
        else return "";
      }}
      processRowUpdate={handleRowUpdate}
      isCellEditable={(params) => {
        return !params.row.isTotalRow;
      }}
      {...props}
      hideFooter={true}
      disableColumnFilter={true}
      disableColumnMenu={!isBetaUser}
    />
  );
};

export default HourlyDataGrid;
