import React from "react";
import { DataGrid, DataGridProps } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGrid)(() => ({
  "& .total": {
    fontWeight: "bold",
    borderTop: "1px solid #aaa",
  },
  "& .selectedDate": {
    backgroundColor: "rgba(57,161,125,0.1)",
  },
  "& .selectedDate:hover": {
    backgroundColor: "rgba(57,161,125,0.15)",
  },
}));

const ForecastDataGrid = ({
  loading,
  rows,
  columns,
  ...props
}: DataGridProps & { lastUpdated: Date }) => {
  return (
    <div style={{ flexGrow: 1 }}>
      <StyledDataGrid
        autoHeight
        loading={loading}
        rows={rows}
        columns={columns}
        rowHeight={35}
        sortModel={[{ field: "businessDate", sort: "asc" }]}
        isRowSelectable={() => false}
        disableRowSelectionOnClick={true}
        {...props}
      />
    </div>
  );
};

export default ForecastDataGrid;
