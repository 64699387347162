import { useMutation, useQuery } from "@apollo/client";
import getSymbolFromCurrency from "currency-symbol-map";
import React from "react";
import { ToastOptions, toast } from "react-toastify";
import CustomizedDialog from ".";
import {
  CREATE_ADJUSTMENT,
  GET_ADJUSTMENT_REASONS2,
  GET_COMBINED_DATA,
} from "../../gql";
import getCurrency from "../../lib/getCurrency";
import { dateAsReverseUTC } from "../../lib/getForecastDataRecords";
import { Reasons } from "../../types";
import NewFigureTable from "./NewFigureTable";
import { NewFigureDialogProps } from "../../types/dialogs";

const options: ToastOptions = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const NewFigureDialog = (props: NewFigureDialogProps) => {
  const { formattedBusinessDate } = props;
  const latestPatraoForecast =
    props.latest_total * props.multiplier + props.totalOffset;
  const latestPatraoForecastRounded = Math.round(latestPatraoForecast);
  const [createAdjustment] = useMutation(CREATE_ADJUSTMENT, {
    refetchQueries: [GET_COMBINED_DATA, "combined_data"],
  });
  const { data: reasons } = useQuery<Reasons>(
    GET_ADJUSTMENT_REASONS2,
    // added no-cache policy as the UI sporadically returned the wrong reason options
    {
      fetchPolicy: "no-cache",
    },
  );

  const handleSubmit = () => {
    adjustToNewForecast(false);
  };

  const handleAccept = () => {
    adjustToNewForecast(true);
  };
  const adjustToNewForecast = (acceptNewForecast: boolean) => {
    const defaultReasonDescription = "Generated forecast accepted"; //TODO  get default reason description from config.js
    const defaultReasonId = reasons?.reasons.find(
      (x) => x.description == defaultReasonDescription,
    )?.id;

    createAdjustment({
      variables: {
        forecastId: acceptNewForecast
          ? props.latest_forecastId
          : props.forecastId,
        businessDate: dateAsReverseUTC(props.businessDate),
        restaurantId: props.restaurantId,
        multiplier: acceptNewForecast ? 1 : props.multiplier,
        adjustment_reasonId: acceptNewForecast
          ? defaultReasonId
          : parseInt(props.adjustment_reasonId as string),
        adjustment_otherReason: acceptNewForecast
          ? ""
          : props.adjustment_otherReason,
      },
    })
      .then(() => {
        toast.success("Adjustment recorded 🚀", { ...options });
      })
      .catch(() => {
        toast.error(`Adjustment not recorded 😢`, {
          ...options,
          autoClose: 5000,
        });
      });
    props.handleClose();
  };
  const currencySymbol = getSymbolFromCurrency(getCurrency(props.restaurantId));

  return (
    <>
      <CustomizedDialog
        title={`REVIEW NEW FORECAST - ${formattedBusinessDate}`}
        actionTitle={`CURRENT PATRAO FORECAST: £${latestPatraoForecastRounded}`}
        {...props}
        content={<NewFigureTable {...props} currencySymbol={currencySymbol!} />}
        enableMultiSubmit={true}
        handleSubmit={handleSubmit} //TODO  allow logic to send two states to submit method
        handleAccept={handleAccept}
      />
    </>
  );
};

export default NewFigureDialog;
