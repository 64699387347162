import React from "react";
import { Drawer, SvgIconProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LineAxis from "@mui/icons-material/LineAxis";
import { useOktaAuth } from "@okta/okta-react";
import { NavLink, useParams } from "react-router-dom";
import { staticRoutes } from "../routes";
import { CustomTheme } from "../theme";
import OktaIcon from "./Icons/Okta";

const LOOKER_DASH_HREF =
  process.env.LOOKER_DASH_HREF ||
  // TODO would be nice to handle currency as well
  "https://nandos.cloud.looker.com/dashboards/rota::sales_forecasting_and_labour_management__summary?Calendar+Date=last+week&Restaurant+No={{cost_centre}}&View+in+Currency=GBP";

const useStyles = makeStyles((theme: CustomTheme) => ({
  paper: {
    minWidth: theme.spacing(38),
  },
  sysBar: {
    height: theme.spacing(7),
    backgroundColor: theme.palette.divider,
  },
  restaurantContainer: {
    margin: `${theme.spacing(1.25)} ${theme.spacing(2)}`,
  },
  restaurantName: {
    ...theme.typography.h6,
    color: theme.palette.text.primary,
    fontWeight: "normal",
    lineHeight: 1.2,
    letterSpacing: "0.15px",
  },
  restaurantIds: {
    ...theme.typography.body2,
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: "0.25px",
    marginTop: "1px",
  },
  appContainer: { padding: `${theme.spacing(2)} 0` },
  adminContainer: {
    padding: `${theme.spacing(2)} 0`,
    borderBottom: `1px ${theme.palette.divider} solid`,
  },
  supportContainer: {
    borderTop: `1px ${theme.palette.divider} solid`,
    padding: `${theme.spacing(2)} 0`,
    marginTop: "auto",
  },
}));

interface DrawerProps {
  open: boolean;
  onClose: () => void;
}

type TParams = { restaurantId: string };

const useIconLinkStyles = makeStyles((theme: CustomTheme) => ({
  container: {
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    textDecoration: "none",
    border: "none",
    "-webkitAppearance": "none",
    "-mozAppearance": "none",
    background: "none",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  text: {
    ...theme.typography.subtitle2,
    marginLeft: theme.spacing(4),
    lineHeight: 1.71,
    display: "inline-block",
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: "0.1px",
    fontWeight: "normal",
    padding: 0,
    margin: 0,
    cursor: "pointer",
    textDecoration: "none",
  },
  icon: {
    color: "rgba(0, 0, 0, 0.6)",
  },
  active: {
    backgroundColor: theme.palette.action.active,
    "& $text": {
      color: theme.palette.action.activeText,
    },
    "& $icon": {
      color: theme.palette.action.activeText,
      fill: "currentColor",
    },
  },
}));

interface IconLinkProps {
  href: string;
  text: string;
  icon: React.FunctionComponentElement<SvgIconProps>;
  onClick: () => void;
}

const IconLink: React.FC<IconLinkProps> = ({ href, text, icon, onClick }) => {
  const classes = useIconLinkStyles();
  return (
    // TODO fix active state of NavLink
    <NavLink onClick={onClick} className={classes.container} to={href}>
      {React.cloneElement<SvgIconProps>(icon, {
        classes: { root: classes.icon },
      })}
      <span className={classes.text}>{text}</span>
    </NavLink>
  );
};

const SimpleLink: React.FC<IconLinkProps> = ({ href, text, icon, onClick }) => {
  const classes = useIconLinkStyles();
  return (
    <a
      onClick={onClick}
      className={classes.container}
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      {React.cloneElement<SvgIconProps>(icon, {
        classes: { root: classes.icon },
      })}
      <span className={classes.text}>{text}</span>
    </a>
  );
};

const LogoutLink: React.FC = () => {
  const { oktaAuth } = useOktaAuth();
  const classes = useIconLinkStyles();
  return (
    <button
      type="button"
      className={classes.container}
      onClick={() => oktaAuth.signOut({ postLogoutRedirectUri: "" })}
    >
      <ExitToAppIcon classes={{ root: classes.icon }} />
      <span className={classes.text}>Log out</span>
    </button>
  );
};

const NavDrawer: React.FC<DrawerProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const { authState } = useOktaAuth();
  return (
    <Drawer
      classes={{ paper: classes.paper }}
      open={open}
      onClose={onClose}
      anchor="left"
    >
      <div className={classes.sysBar} />
      {authState?.isAuthenticated && (
        <NavigationDrawerContent onClose={onClose} />
      )}
      <div className={classes.supportContainer}>
        <IconLink
          onClick={onClose}
          text="Home"
          href={staticRoutes.index}
          icon={<ExitToAppIcon />}
        />
        <IconLink
          onClick={onClose}
          text="Help &amp; Support"
          href={staticRoutes.help}
          icon={<ErrorOutlineIcon />}
        />
        <SimpleLink
          onClick={onClose}
          text="Back to Okta"
          href={`https://${process.env.REACT_APP_OKTA_ORG_DOMAIN}`}
          icon={<OktaIcon />}
        />
        {authState?.isAuthenticated ? <LogoutLink /> : null}
      </div>
    </Drawer>
  );
};

const NavigationDrawerContent: React.FC<Pick<DrawerProps, "onClose">> = ({
  onClose,
}) => {
  const { restaurantId } = useParams<TParams>();

  return restaurantId ? (
    <SimpleLink
      onClick={onClose}
      text="Looker Dashboard"
      href={LOOKER_DASH_HREF.replace("{{cost_centre}}", restaurantId)}
      icon={<LineAxis />}
    />
  ) : (
    <></>
  );
};

export default NavDrawer;
