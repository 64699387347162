import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import formatNumber from "../../lib/formatNumber";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import { VarianceTableProps } from "../../types/dialogs";

function createData(
  name: string,
  current_forecast: number,
  actual_sales: number,
) {
  return { name, current_forecast, actual_sales };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const formatPercentage = (x: number, y: number) => {
  return y || x != 0 ? ((y - x) / x) * 100 : 0;
};

const checkDirection = (x: number, y: number, threshold: number) => {
  const percentage: number = y || x != 0 ? ((y - x) / x) * 100 : 0;
  if (percentage > threshold || 0) {
    return <ArrowDropUp color="success" />;
  }
  if (percentage < -threshold || 0) {
    return <ArrowDropDown color="error" />;
  }
};

export default function VarianceTable(props: VarianceTableProps) {
  const {
    eatIn,
    eatInOffset,
    delivery,
    deliveryOffset,
    collect,
    collectOffset,
    total,
    totalOffset,
    multiplier,
    actual_eatIn,
    actual_delivery,
    actual_collect,
    actual_total,
    currencySymbol,
  } = props;

  const currentForecast = (fulfilmentTotal: number, offset: number) => {
    return fulfilmentTotal * multiplier + offset;
  };

  const rows = [
    createData("EAT IN", currentForecast(eatIn, eatInOffset), actual_eatIn),
    createData(
      "DELIVERY",
      currentForecast(delivery, deliveryOffset),
      actual_delivery,
    ),
    createData(
      "COLLECT",
      currentForecast(collect, collectOffset),
      actual_collect,
    ),
    createData("TOTAL", currentForecast(total, totalOffset), actual_total),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell
              align="right"
              sx={{ background: (theme) => theme.palette.action.focus }}
            >
              ({currencySymbol}) CURRENT FORECAST
            </TableCell>
            <TableCell align="right">({currencySymbol}) ACTUAL SALES</TableCell>
            {/* <TableCell align="right">({currencySymbol}) VARIANCE</TableCell> */}
            <TableCell align="right">(%) VARIANCE</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell
                align="right"
                sx={{ background: (theme) => theme.palette.action.focus }}
                data-testid={row.name}
              >
                {formatNumber(row.current_forecast)}
              </TableCell>
              <TableCell align="right">
                {formatNumber(row.actual_sales)}
              </TableCell>
              {/* <TableCell align="right">
                {formatNumber(row.actual_sales - row.current_forecast)}
                {checkDirection(row.current_forecast, row.actual_sales, 0)}
              </TableCell> */}
              <TableCell align="right">
                {formatNumber(
                  formatPercentage(row.current_forecast, row.actual_sales),
                  1,
                )}
                {checkDirection(row.current_forecast, row.actual_sales, 0)}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
