import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import * as React from "react";
import formatNumber from "../../lib/formatNumber";
import { ICombinedRows } from "../../types";
import { AdjustmentTableProps } from "../../types/dialogs";

function createData(
  name: string,
  current_forecast: number,
  latest_forecast: number,
) {
  return { name, current_forecast, latest_forecast };
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export default function AdjustmentTable(props: AdjustmentTableProps) {
  const { newMultiplier, currencySymbol } = props;
  const {
    eatIn,
    delivery,
    collect,
    latest_eatIn,
    latest_delivery,
    latest_collect,
    latest_total,
    eatInOffset,
    deliveryOffset,
    collectOffset,
    totalOffset,
  } = props.selectedRow as ICombinedRows;

  const displayTotal =
    eatIn * newMultiplier +
    delivery * newMultiplier +
    collect * newMultiplier +
    totalOffset;

  const rows = [
    createData("EAT IN", eatIn * newMultiplier + eatInOffset, latest_eatIn),
    createData(
      "DELIVERY",
      delivery * newMultiplier + deliveryOffset,
      latest_delivery,
    ),
    createData(
      "COLLECT",
      collect * newMultiplier + collectOffset,
      latest_collect,
    ),
    createData("TOTAL", displayTotal, latest_total),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell
              align="right"
              sx={{ background: (theme) => theme.palette.action.focus }}
            >
              ({currencySymbol}) CURRENT FORECAST
            </TableCell>
            <TableCell align="right">
              ({currencySymbol}) LATEST FORECAST
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell
                align="right"
                sx={{ background: (theme) => theme.palette.action.focus }}
              >
                {formatNumber(row.current_forecast)}
              </TableCell>
              <TableCell align="right">
                {formatNumber(row.latest_forecast)}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
