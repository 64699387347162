import React from "react";
import BaseErrorPage from "../components/BaseErrorPage";
import BrokenEggIcon from "../components/Icons/BrokenEggIcon";
import ServiceDeskEmailLink from "../components/ServiceDeskEmailLink";

const ServiceNotAvailable: React.FC = () => {
  const refresh = () => {
    window.location.reload();
  };

  return (
    <BaseErrorPage
      icon={<BrokenEggIcon />}
      heading="Service not available"
      bodyPrimary="We’re sorry, Sales Forecasting is not available right now. Please refresh the page or try again later."
      bodySecondary={
        <>
          If you continue to have issues, please contact Service desk on{" "}
          <ServiceDeskEmailLink /> or call them on 0203 040 5600.
        </>
      }
      button={{ label: "Refresh", onClick: refresh }}
    />
  );
};
export default ServiceNotAvailable;
