import React from "react";
import Layout from "../components/Layout";
import DataGrid from "../components/Forecast";
import { ApolloProvider } from "@apollo/client";
import authService from "../lib/authService";
import createClient from "../lib/createClient";
import { useNavigate, useParams } from "react-router-dom";
import config from "../lib/configApi";
import Box from "@mui/material/Box";
import { UserParamsBar } from "../components/Forecast/UserParamsBar";
import { useRestaurantData } from "../hooks/useRestaurantData/useRestaurantData";

const getAccessToken = authService.getAccessToken.bind(authService);
const forecastClient = createClient(config.api.url, getAccessToken);

type TParams = { restaurantId: string };

const Dashboard: React.FC = () => {
  const { restaurantId } = useParams<TParams>();
  const navigate = useNavigate();

  if (!restaurantId) {
    navigate("/");
    return null;
  }

  const { data } = useRestaurantData();

  const isValidRestaurant = data.find(
    (restaurant) => restaurant.restaurantId === restaurantId,
  );

  if (!isValidRestaurant) {
    navigate("/");
    return null;
  }

  return (
    <Layout>
      <Box padding={2} display={"flex"} flexDirection={"column"} gap={2}>
        <ApolloProvider client={forecastClient}>
          <UserParamsBar />
          <DataGrid restaurantId={restaurantId} />
        </ApolloProvider>
      </Box>
    </Layout>
  );
};

export default Dashboard;
