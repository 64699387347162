import React from "react";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Button from "@mui/material/Button";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    maxWidth: 811,
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    color: "rgba(0, 0, 0, 0.87)",
  },
  iconContainer: {
    marginTop: 89,
    marginBottom: theme.spacing(4),
  },
  heading: {
    height: 24,
    fontSize: 24,
    fontWeight: 500,
    lineHeight: 1,
    letterSpacing: 0.18,
    marginBottom: theme.spacing(3),
  },
  headingMarginTop: {
    marginTop: 149,
  },
  bodyPrimary: {
    fontSize: 16,
    lineHeight: 1.5,
    letterSpacing: 0.5,
    marginBottom: theme.spacing(1.5),
  },
  bodySecondary: {
    fontSize: 14,
    lineHeight: 1.43,
    letterSpacing: 0.25,
    color: "rgba(0, 0, 0, 0.74)",
  },
  loginButton: {
    boxShadow: "none",
    letterSpacing: 1.25,
    fontSize: 14,
    textTransform: "uppercase",
    marginBottom: theme.spacing(4),
  },
}));

const BaseErrorPage: React.FC<{
  icon?: React.ReactNode;
  heading: string;
  bodyPrimary: string;
  bodySecondary: React.ReactNode;
  button?: {
    label: string;
    onClick: () => void;
  };
}> = ({ icon, heading, bodyPrimary, bodySecondary, button }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {icon && <div className={classes.iconContainer}>{icon}</div>}
      <Typography
        className={clsx(classes.heading, { [classes.headingMarginTop]: !icon })}
      >
        {heading}
      </Typography>
      <Typography className={classes.bodyPrimary}>{bodyPrimary}</Typography>
      {button && (
        <Button
          className={classes.loginButton}
          color="secondary"
          variant="contained"
          onClick={button.onClick}
        >
          {button.label}
        </Button>
      )}
      <Typography className={classes.bodySecondary}>{bodySecondary}</Typography>
    </div>
  );
};
export default BaseErrorPage;
