import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import * as React from "react";
import formatNumber from "../../lib/formatNumber";
import { NewFigureTableProps } from "../../types/dialogs";

function createData(
  name: string,
  current_forecast: number,
  new_generated_forecast: number,
  previous_generated_forecast: number,
) {
  return {
    name,
    current_forecast,
    new_generated_forecast,
    previous_generated_forecast,
  };
}

const StyledTableRow = styled(TableRow)(() => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#fafafa",
    height: 10,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const checkDirection = (x: number, y: number, threshold: number) => {
  const percentage: number = y || x != 0 ? ((y - x) / x) * 100 : 0;
  if (percentage > threshold || 0) {
    return <ArrowDropUp color="success" />;
  }
  if (percentage < -threshold || 0) {
    return <ArrowDropDown color="error" />;
  }
};

export default function NewFigureTable(props: NewFigureTableProps) {
  const {
    eatIn,
    delivery,
    collect,
    total,
    multiplier,
    latest_eatIn,
    latest_delivery,
    latest_collect,
    latest_total,
    currencySymbol,
    collectOffset,
    deliveryOffset,
    eatInOffset,
    totalOffset,
    previous_eatIn,
    previous_delivery,
    previous_collect,
    previous_total,
  } = props;

  const rows = [
    createData(
      "EAT IN",
      Math.round(eatIn * multiplier + eatInOffset),
      Math.round(latest_eatIn + eatInOffset),
      Math.round(previous_eatIn + eatInOffset),
    ),
    createData(
      "DELIVERY",
      Math.round(delivery * multiplier + deliveryOffset),
      Math.round(latest_delivery + deliveryOffset),
      Math.round(previous_delivery + deliveryOffset),
    ),
    createData(
      "COLLECT",
      Math.round(collect * multiplier + collectOffset),
      Math.round(latest_collect + collectOffset),
      Math.round(previous_collect + collectOffset),
    ),
    createData(
      "TOTAL",
      Math.round(total * multiplier + totalOffset),
      Math.round(latest_total + totalOffset),
      Math.round(previous_total + totalOffset),
    ),
  ];

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">
              ({currencySymbol}) PREVIOUSLY GENERATED FORECAST
            </TableCell>
            <TableCell align="right">
              ({currencySymbol}) NEW GENERATED FORECAST
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <StyledTableRow
              key={row.name}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">
                {formatNumber(row.previous_generated_forecast)}
              </TableCell>
              <TableCell align="right">
                {formatNumber(row.new_generated_forecast)}
                {checkDirection(
                  row.previous_generated_forecast,
                  row.new_generated_forecast,
                  0,
                )}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
