import { ApolloClient, ApolloLink } from "@apollo/client";
import { InMemoryCache, NormalizedCacheObject } from "@apollo/client/cache";
import { ErrorResponse, onError } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";
import authService from "../authService";
import customFetch from "../customFetch";

const isUnauthorized = ({ networkError }: ErrorResponse) =>
  networkError &&
  "statusCode" in networkError &&
  [401, 403].includes(networkError.statusCode);

// Force the user to log in if an API client returns an unauthorized status
const errorLink = onError((errorResponse) => {
  if (!isUnauthorized(errorResponse)) return;
  authService.signInWithRedirect({ originalUri: "/" });
});

const createClient = (
  uri: string,
  getAccessToken: () => string | undefined,
  cache?: InMemoryCache,
): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: ApolloLink.from([
      errorLink,
      new HttpLink({
        uri: `${uri}/graphql`,
        fetch: customFetch(getAccessToken) as (
          input: RequestInfo | URL,
          init?: RequestInit | undefined,
        ) => Promise<Response>,
      }),
    ]),
    cache: cache || new InMemoryCache(),
    defaultOptions: {
      // https://github.com/apollographql/react-apollo/issues/3571
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });
};

export default createClient;
