import formatNumber from "../../../lib/formatNumber";
import { Tooltip } from "@mui/material";
import React from "react";

const DailyEditedCell = ({
  value,
  offset,
  multiplier,
}: {
  value: number;
  offset: number;
  multiplier: number;
}) => {
  const originalValue = value * multiplier;

  if (offset === 0) {
    return <p>{formatNumber(originalValue)}</p>;
  }

  return (
    <Tooltip title={`Includes hourly adjustments`} disableFocusListener={true}>
      <p style={{ textDecoration: "underline", fontWeight: "bold" }}>
        {formatNumber(originalValue + offset)}
      </p>
    </Tooltip>
  );
};

export default DailyEditedCell;
