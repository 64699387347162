import React, { useState } from "react";
import CustomizedDialog from ".";
import {
  GET_VARIANCE_REASONS,
  CREATE_VARIANCE,
  GET_COMBINED_DATA,
} from "../../gql";
import ReasonHandler from "./ReasonHandler";
import VarianceTable from "./VarianceTable";
import { ReasonResponse } from "../../types";
import { useMutation } from "@apollo/client";
import getSymbolFromCurrency from "currency-symbol-map";
import getCurrency from "../../lib/getCurrency";
import { toast, ToastOptions } from "react-toastify";
import { dateAsReverseUTC } from "../../lib/getForecastDataRecords";
import { VarianceDialogProps } from "../../types/dialogs";

const options: ToastOptions = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const MIN_CHARACTERS = 5;

const VarianceDialog = (props: VarianceDialogProps) => {
  const { formattedBusinessDate } = props;

  const [currentSelection, setCurrentSelection] = useState<ReasonResponse>({
    id: "",
    description: "",
    otherValue: "",
  });

  const [createVariance] = useMutation(CREATE_VARIANCE, {
    refetchQueries: [GET_COMBINED_DATA, "combined_data"],
  });

  const handleSelectionChange = (x: ReasonResponse) => {
    setCurrentSelection(x);
  };

  const handleSubmit = () => {
    if (isNaN(parseInt(currentSelection.id))) {
      toast.warning(`Variance reason not selected 🤌`, {
        ...options,
        autoClose: 5000,
      });
      return;
    }
    if (
      currentSelection.description === "Other" &&
      currentSelection.otherValue.length < MIN_CHARACTERS
    ) {
      toast.warning(
        `Please be more descriptive, min ${MIN_CHARACTERS} characters`,
        {
          ...options,
          autoClose: 5000,
        },
      );
      return;
    }
    createVariance({
      variables: {
        businessDate: dateAsReverseUTC(props.businessDate),
        restaurantId: props.restaurantId,
        totalForecast: props.total * props.multiplier,
        totalSales: props.actual_total,
        variance_reasonId: parseInt(currentSelection.id),
        variance_otherReason: currentSelection.otherValue,
      },
    })
      .then(() => {
        toast.success("Variance recorded 🚀", { ...options });
      })
      .catch((error) => {
        toast.error(`Variance not recorded 😢`, {
          ...options,
          autoClose: 5000,
        });
        throw error;
      });
    props.handleClose();
  };

  const currencySymbol = getSymbolFromCurrency(getCurrency(props.restaurantId));

  return (
    <>
      <CustomizedDialog
        title={`SALES VARIANCE - ${formattedBusinessDate}`}
        actionTitle="ADD VARIANCE REASON"
        {...props}
        content={<VarianceTable {...props} currencySymbol={currencySymbol!} />}
        selector={
          <ReasonHandler
            recordedReason={props.variance_reasonId as string}
            recordedOtherValue={props.variance_otherReason}
            gqlReasonQuery={GET_VARIANCE_REASONS}
            handleChange={(x) => handleSelectionChange(x)}
          />
        }
        enableMultiSubmit={false}
        handleSubmit={handleSubmit}
      />
    </>
  );
};

export default VarianceDialog;
