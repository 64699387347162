import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import RestaurantAppBar from "./AppBar/RestaurantAppBar";
import Main from "./Main";
import NavigationDrawer from "./NavigationDrawer";
import { Alert } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    zIndex: 1,
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
    position: "relative",
    width: "100%",
  },
  appFrame: {
    display: "flex",
    flexDirection: "column",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    flexBasis: 0,
    minWidth: 0,
  },
}));

type LayoutProps = {
  children?: React.ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(false);
  return (
    <div className={classes.root}>
      <div className={classes.appFrame} />
      <RestaurantAppBar
        onMenuClick={() => {
          setDrawerOpen(true);
        }}
      />
      <Alert variant="filled" severity="info">
        Your sales forecasts now account for the impact of the Christmas period,
        with the data range extended through to 12th January 2025.
      </Alert>
      <NavigationDrawer
        open={drawerOpen}
        onClose={() => {
          setDrawerOpen(false);
        }}
      />
      <Main>
        <div className={classes.content}>{children}</div>
      </Main>
    </div>
  );
};

export default Layout;
