import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_HOURLY_ADJUSTMENT,
  GET_ADJUSTMENT_REASONS,
  GET_COMBINED_DATA,
} from "../../../gql";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { FormControl, Stack, TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { Save } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import HourlyChangesList from "./HourlyChangesDisplay";
import HourlyReasonsInput from "./HourlyReasonsInput";
import { ProcessHourlyEdits } from "../../../dataMappers/ProcessHourlyEdits/processHourlyEdits";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { IHourlyEditDiff } from "../../../types";

const HourlyEditDialog = ({
  open,
  onClose,
  editedRows,
  onConfirmSave,
}: {
  open: boolean;
  onClose: () => void;
  editedRows: IHourlyEditDiff[];
  onConfirmSave: () => void;
}) => {
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReasonValue, setOtherReasonValue] = useState("");
  const showOtherReason = selectedReason === "13";

  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const { data: reasons } = useQuery(GET_ADJUSTMENT_REASONS);
  const [
    createAdjustment,
    {
      data: adjustmentResponse,
      loading: adjustmentPending,
      error: adjustmentError,
    },
  ] = useMutation(CREATE_HOURLY_ADJUSTMENT, {
    refetchQueries: [GET_COMBINED_DATA, "combined_data"],
  });
  const handleOtherReasonChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setOtherReasonValue(event.target.value);
  };

  const handleClickSubmit = async () => {
    const processedRows = ProcessHourlyEdits(
      editedRows,
      parseInt(selectedReason),
      otherReasonValue,
    );
    createAdjustment({ variables: processedRows });
    setLoading(true);
  };

  useEffect(() => {
    if (adjustmentResponse && !adjustmentPending) {
      setTimeout(() => {
        onConfirmSave();
        setOtherReasonValue("");
        setSelectedReason("");
        setShowError(false);
        setLoading(false);
      }, 1500);
    }
  }, [adjustmentResponse, adjustmentPending]);

  useEffect(() => {
    if (adjustmentError) {
      setTimeout(() => {
        setLoading(false);
        setShowError(true);
      }, 1500);
    }
  }, [adjustmentError]);

  const handleClose = () => {
    onClose();
    setOtherReasonValue("");
    setSelectedReason("");
    setShowError(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <p>
          {loading
            ? "Processing your changes"
            : `${editedRows.length} change${
                editedRows.length > 1 ? "s" : ""
              } to review`}
        </p>
      </DialogTitle>
      {showError && !loading && (
        <DialogContent sx={{ paddingBottom: 0 }}>
          <Typography sx={{ color: "red" }}>
            There was an error submitting your changes. <br />
            Please try again.
          </Typography>
        </DialogContent>
      )}
      {loading ? (
        <LoadingComponent />
      ) : (
        <Box>
          <DialogContent
            sx={{ display: "flex", flexDirection: "column", gap: 1 }}
          >
            <HourlyChangesList editedRows={editedRows} />
            {reasons && (
              <Stack>
                <HourlyReasonsInput
                  reasons={reasons.adjustment_reasons}
                  selectedReason={selectedReason}
                  handleReasonSelection={setSelectedReason}
                />
                {showOtherReason && (
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-multiline-static"
                      label="Other Reason"
                      inputProps={{
                        maxLength: 160,
                      }}
                      multiline
                      helperText={`${otherReasonValue.length}/160`}
                      required={showOtherReason}
                      value={otherReasonValue}
                      onChange={handleOtherReasonChange}
                      rows={2}
                    />
                  </FormControl>
                )}
              </Stack>
            )}
          </DialogContent>
          <DialogActions>
            <Button autoFocus variant={"outlined"} onClick={handleClose}>
              Continue Editing
            </Button>
            <Button
              variant={"contained"}
              color={"success"}
              onClick={handleClickSubmit}
              endIcon={<Save />}
              disabled={
                !selectedReason ||
                (showOtherReason && otherReasonValue.length < 5)
              }
            >
              Save
            </Button>
          </DialogActions>
        </Box>
      )}
    </Dialog>
  );
};

export default HourlyEditDialog;

const LoadingComponent = () => {
  return (
    <Box
      sx={{
        width: 400,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
      <Typography>Submitting changes...</Typography>
    </Box>
  );
};
