import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { HourlyEditToggle } from "../../types";
import Box from "@mui/material/Box";
import { useEditingContext } from "../../contexts/EditingContext";

export const HourlyEditToggleGroup = () => {
  const { editType, setEditType } = useEditingContext();

  const handleEditType = (
    event: React.MouseEvent<HTMLElement>,
    newEditType: HourlyEditToggle,
  ) => {
    if (newEditType !== null) {
      setEditType(newEditType);
    }
  };

  return (
    <Box>
      <ToggleButtonGroup
        size={"small"}
        value={editType}
        exclusive
        onChange={handleEditType}
        aria-label={"edit type"}
        id={"edit-type"}
      >
        <ToggleButton sx={{ color: "black" }} value={"total"}>
          Total
        </ToggleButton>
        <ToggleButton value={"channel"}>Channel</ToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};
