import { generatePath } from "react-router-dom";

const staticRoutes = {
  index: "/",
  login: "/implicit/callback",
  help: "/help",
  restaurant: "/restaurant",
  maintenance: "/maintenance",
};

const patterns = {
  restaurant: "/restaurant/:restaurantId",
  dashboard: "/restaurant/:restaurantId/dashboard",
};

const getRestaurantRoute = (restaurantId: string): string =>
  generatePath(patterns.restaurant, { restaurantId });
const getDashboardRoute = (restaurantId: string): string =>
  generatePath(patterns.dashboard, { restaurantId });

export { getRestaurantRoute, getDashboardRoute, patterns, staticRoutes };
