import { useQuery } from "@tanstack/react-query";
import config from "../../lib/configApi";
import authService from "../../lib/authService";

export const useRestaurantTrial = (
  restaurantId?: string,
  trialName?: string,
) => {
  return useQuery({
    queryKey: ["trials", restaurantId, trialName],
    enabled: !!restaurantId && !!trialName,
    queryFn: async () => {
      const res = await fetch(`${config.api.url}/trials/${restaurantId}`, {
        headers: {
          Authorization: `Bearer ${authService.getAccessToken()}`,
        },
      });

      if (!res.ok) {
        throw new Error("Something went wrong fetching trials");
      }

      const { trials }: { trials: string[] } = await res.json();

      if (!trials) {
        return false;
      }

      const isTrialEnabled = trials.some((trial) => trial === trialName);

      return isTrialEnabled;
    },
  });
};
