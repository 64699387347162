import { TParams } from "../../types";
import { useParams } from "react-router-dom";
import { useRestaurantTrial } from "../../hooks/useRestaurantTrial/useRestaurantTrial";
import Typography from "@mui/material/Typography";
import React from "react";

export const DisclaimerCopy = () => {
  const { restaurantId } = useParams<TParams>();
  const { data: eventsEnabled } = useRestaurantTrial(
    restaurantId,
    "forecast_events_display",
  );

  if (eventsEnabled) {
    return (
      <Typography variant={"body1"} component={"h2"}>
        Automatically generated forecast considers bank holidays and the events
        displayed on the Forecast dashboard.
      </Typography>
    );
  }

  return (
    <Typography variant="body1" component="h2">
      Note: Automatically generated forecast considers bank holidays and is
      updated every week.
    </Typography>
  );
};
