import ForecastDataGrid from "./ForecastDataGrid";
import React from "react";
import { GridColDef, GridRowParams, GridValidRowModel } from "@mui/x-data-grid";
import formatNumber from "../../../lib/formatNumber";
import { useFeatureFlagsContext } from "../../../contexts/FeatureFlagsContext";

const WeeklyDataGrid = ({ rows }: { rows: GridValidRowModel[] }) => {
  const columnDefinition: GridColDef[] = [
    {
      field: "id",
    },
    {
      field: "formattedBusinessDate",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "weekId",
      headerName: "Week-Id",
    },
    {
      field: "businessDate",
      headerName: "Raw-Date",
    },
    {
      field: "eatIn",
      headerName: "Eat-In",
      description: "Eat-In forecast",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.eatIn),
    },
    {
      field: "delivery",
      headerName: "Delivery",
      description: "Delivery forecast",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.delivery),
    },
    {
      field: "collect",
      headerName: "Collect",
      description: "Collect forecast",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.collect),
    },
    {
      field: "total",
      headerName: "Total",
      description: "Total forecast",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.total),
    },
    {
      field: "actual_total",
      headerName: "Actual",
      description: "Actual sales",
      type: "number",
      valueGetter: ({ row }) => formatNumber(row.actual_total),
    },
  ];
  const { isBetaUser } = useFeatureFlagsContext();

  return (
    <ForecastDataGrid
      columns={columnDefinition}
      rows={rows}
      lastUpdated={new Date()}
      initialState={{
        columns: {
          columnVisibilityModel: {
            businessDate: false,
            weekId: false,
            id: false,
          },
        },
      }}
      getRowClassName={(params: GridRowParams) => {
        if (params.row.isTotalRow) return "total";
        else return "";
      }}
      disableColumnMenu={!isBetaUser}
    />
  );
};

export default WeeklyDataGrid;
