import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BaseErrorPage from "../components/BaseErrorPage";
import ConstructionIcon from "@mui/icons-material/Construction";

interface CustomizedState {
  message: string;
}

const Maintenance: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const state = location.state as CustomizedState;
  const message = state ? state.message : "Null";

  return (
    <BaseErrorPage
      icon={<ConstructionIcon fontSize="large" />}
      heading="Maintenance currently running"
      bodyPrimary={message}
      bodySecondary={""}
      button={{
        label: "Return Home",
        onClick: () => navigate("/"),
      }}
    />
  );
};
export default Maintenance;
