import React from "react";
import { Container } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  content: {
    display: "flex",
    flexGrow: 1,
  },
}));

type MainProps = {
  children?: React.ReactNode;
};

const Main = ({ children }: MainProps) => {
  const classes = useStyles();
  if (!children) return null;

  return (
    <main className={classes.content}>
      <Container>{children}</Container>
    </main>
  );
};

export default Main;
