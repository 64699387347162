import { useOktaAuth } from "@okta/okta-react";
import authService from "./lib/authService";

const developers = [
  "terezadm@nandos.co.uk",
  "rafee.jenkins@nandos.co.uk",
  "aurora.delrioperez@nandos.co.uk",
  "samuel.talbottwhite@nandos.co.uk",
  "matthew.eland@nandos.co.uk",
];

export const getAccessToken = authService.getAccessToken.bind(authService);

export function isBetaUser(): boolean {
  const oktaUser = useOktaAuth();
  const userEmail = oktaUser?.authState?.idToken?.claims?.email ?? "";
  return developers.includes(userEmail);
}
