import { GridRowParams } from "@mui/x-data-grid";
import React, { useState } from "react";
import { ICombinedRows, SelectedRow } from "../../../types";
import AdjustmentDialog from "../../CustomDialogs/AdjustmentDialog";
import NewFigureDialog from "../../CustomDialogs/NewFigureDialog";
import VarianceDialog from "../../CustomDialogs/VarianceDialog";
import DailyDataGrid from "../DataGrid/DailyDataGrid";
import Box from "@mui/material/Box";
import { RestaurantEventsList } from "./RestaurantEventsList";

const DailyLayout = ({ rows }: { rows: ICombinedRows[] }) => {
  const [selectedRow, setSelectedRow] = useState<SelectedRow>(
    {} as SelectedRow,
  );
  const [adjustmentDialogOpen, setAdjustmentDialogOpen] = useState(false);

  const openAdjustmentDialog = ({ row }: GridRowParams) => {
    setSelectedRow({ ...row });
    setAdjustmentDialogOpen(true);
  };

  const closeAdjustmentDialog = () => {
    setAdjustmentDialogOpen(false);
  };

  const [newFigureDialogOpen, setNewFigureDialogOpen] = useState(false);

  const openNewFiguresDialog = ({ row }: GridRowParams) => {
    setSelectedRow({ ...row });
    setNewFigureDialogOpen(true);
  };

  const closeNewFiguresDialog = () => {
    setNewFigureDialogOpen(false);
  };

  const [varianceDialogOpen, setVarianceDialogOpen] = useState(false);

  const openVarianceDialog = ({ row }: GridRowParams) => {
    setSelectedRow({ ...row });
    setVarianceDialogOpen(true);
  };

  const closeVarianceDialog = () => {
    setVarianceDialogOpen(false);
  };

  return (
    <Box>
      <AdjustmentDialog
        open={adjustmentDialogOpen}
        handleOpen={openAdjustmentDialog}
        handleClose={closeAdjustmentDialog}
        selectedRow={selectedRow}
      />
      <NewFigureDialog
        open={newFigureDialogOpen}
        handleOpen={openNewFiguresDialog}
        handleClose={closeNewFiguresDialog}
        {...selectedRow}
      />
      <VarianceDialog
        open={varianceDialogOpen}
        handleOpen={openVarianceDialog}
        handleClose={closeVarianceDialog}
        {...selectedRow}
      />
      <Box
        sx={{
          display: "flex",
          gap: "20px",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            flexDirection: "column",
          }}
        >
          <DailyDataGrid
            rows={rows}
            openNewFiguresDialog={openNewFiguresDialog}
            openAdjustmentDialog={openAdjustmentDialog}
            openVarianceDialog={openVarianceDialog}
          />
          <RestaurantEventsList />
        </Box>
      </Box>
    </Box>
  );
};

export default DailyLayout;
